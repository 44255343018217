import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { AuthState } from 'src/@types/auth/auth';
import { dispatch } from 'src/redux/store';
import { setSession } from 'src/utils/jwt';
import { reset as resetMicroRevenueStore } from 'src/redux/slices/dashboard/micro_revenue_report';
import { reset as resetMonthlyStore } from 'src/redux/slices/dashboard/monthly_revenue_report';
import { reset as resetTransactionStore } from 'src/redux/slices/dashboard/transactions';
import { reset as resetYearlyStore } from 'src/redux/slices/dashboard/yearly_revenue_report';
import { reset as resetUrssafStore } from 'src/redux/slices/reports/urssaf';
import { reset as resetErrorStore } from 'src/redux/slices/error/error';
import { reset as resetExpenseTypeStore } from 'src/redux/slices/expenses/expense_types';
import { reset as resetExpenseStore } from 'src/redux/slices/expenses/expenses';
import { reset as resetCompanyStore, getCompany } from 'src/redux/slices/company/company';
import { reset as resetInvoiceStore } from 'src/redux/slices/invoice/invoices';
import { reset as resetMaintenanceStore } from 'src/redux/slices/maintenance/maintenance';
import { reset as resetNotificationStore } from 'src/redux/slices/notifications/notifications';
import { reset as resetAppIntegrationStore } from 'src/redux/slices/integrations/app_integrations';
import { reset as resetActivityStore } from 'src/redux/slices/activities/activities';
import { reset as resetArticleStore } from 'src/redux/slices/articles/articles';
import { reset as resetCountryStore } from 'src/redux/slices/countries/countries';
import { reset as resetCurrencyStore } from 'src/redux/slices/currencies/currencies';
import { reset as resetPaymentStore } from 'src/redux/slices/payments/payments';
import { reset as resetClientStore } from 'src/redux/slices/client/clients';
import { reset as resetCreditNoteStore } from 'src/redux/slices/credit_notes/credit_notes';
import { reset as resetQuoteStore } from 'src/redux/slices/quotes/quotes';
import { reset as resetRecurringInvStore } from 'src/redux/slices/recurring/recurring';
import { reset as resetRecurringExpStore } from 'src/redux/slices/recurring/recurring_expense';
import { reset as resetSupplierStore } from 'src/redux/slices/supplier/suppliers';
import { reset as resetVatAppTaxesStore } from 'src/redux/slices/vat/applicable_taxes';
import { reset as resetVatStore } from 'src/redux/slices/vat/vat';
import { reset as resetBankStore } from 'src/redux/slices/banks/banks';
import { reset as resetBankTransactionStore } from 'src/redux/slices/banks/transactions';
import { reset as resetBankRuleStore } from 'src/redux/slices/banks_rules/banks_rules';
import { reset as resetDeliveryNoteStore } from 'src/redux/slices/delivery_notes/delivery_notes';
import { updateCurrentPlan, reset as resetPlans } from '../plans/plans';
import { getBillingInvoices, reset as resetBillingInvoices } from '../billing_invoices/billing_invoices';
// -----------------------------------------------------------------------------

const initialState: AuthState = {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    isInitialized: false,
    impersonation: false,
    user: null,
    multi_factor_required: false,
    isRestricted: true,
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // START LOADING / INITIALIZE
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // EVENTS
        loginSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.impersonation = action.payload.impersonation ?? false;
            state.multi_factor_required = action.payload.multi_factor_required ?? false;
            state.isRestricted = action.payload.user?.restricted ?? false;
            return state;
        },

        loginMultiFactorSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.user = action.payload.user;
            state.impersonation = action.payload.impersonation ?? false;
            state.multi_factor_required = action.payload.multi_factor_required ?? false;
            state.isRestricted = action.payload.user?.restricted ?? false;
            return state;
        },

        loginInitializeSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = action.payload.isAuthenticated;
            state.user = action.payload.user;
            state.impersonation = action.payload.impersonation ?? false
            state.isInitialized = true;
            state.isRestricted = action.payload.user.restricted ?? false;
            return state;
        },

        logout: (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.impersonation = false;
            state.isInitialized = true;
            state.isRestricted = false;
            return state;
        },

        // EVENTS
        updateUser: (state, action) => {
            state.isLoading = false;
            state.user = action.payload;
            return state;
        },

        // END LOADING
        endLoading: (state) => {
            state.isLoading = false;
            return state;
        },

        apiKeyDeleted: (state, action) => {
            state.isLoading = false;
            let user = JSON.parse(JSON.stringify(state.user));
            user?.api_keys?.splice(action.payload, 1);
            state.user = user;
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function login(data: any, path: string = "/login", redirect: string = '') {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(path, data);

            const { access_token, user, type, redirect_url, impersonation, multi_factor_required } = response.data;

            setSession(access_token, type);

            if (redirect_url || redirect) {
                window.location.href = redirect || redirect_url;
                return null
            } else {
                if (multi_factor_required) {
                    dispatch(slice.actions.loginMultiFactorSuccess({ user, impersonation, multi_factor_required }));
                } else {
                    // FETCH COMPANY
                    await dispatch(getCompany());
                    // store impersonation in state to enable on reload after logged in user
                    localStorage.setItem('impersonation', impersonation);
                    if (!user.roles) user.roles = [];
                    dispatch(slice.actions.loginSuccess({ user, impersonation, multi_factor_required }));
                    const plan = user.plan?.split('_') ?? ['essential', 'yearly'];
                    dispatch(updateCurrentPlan({ plan: plan[0], interval: plan[1] ?? 'yearly', subscription_status: user.subscription_status, billing_provider: user?.billing_provider }))
                }
                return response.data
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error)
        }
    };
}

export function register(data: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post('/register', data);
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return Promise.reject(error)
        }
    };
}

export function loginInitialize() {
    return async () => {

        dispatch(slice.actions.startLoading());
        try {
            const access_token = localStorage.getItem('access_token');
            const access_token_type = localStorage.getItem('access_token_type');

            if (access_token) {
                setSession(access_token, access_token_type ?? 'Bearer');

                const response = await axios.get('/users/me');
                // FETCH COMPANY
                await dispatch(getCompany());

                const { data } = response;

                if (!data.roles) data.roles = [];
                dispatch(slice.actions.loginInitializeSuccess({
                    isAuthenticated: true,
                    user: data,
                    impersonation: JSON.parse(localStorage.getItem('impersonation') ?? 'false')//localStorage.getItem('impersonation') === 'true' ?? false,
                }));
                const plan = data.plan?.split('_') ?? ['essential', 'yearly'];
                dispatch(updateCurrentPlan({ plan: plan[0], interval: plan[1] ?? 'yearly', subscription_status: data.subscription_status, billing_provider: data.billing_provider }))
            } else {
                // if (access_token) await dispatch(addNotification("auth.you_are_logout", "error", "auth.you_are_logout"))
                dispatch(logout());
            }
        } catch (error) {
            console.log(error)
            dispatch(logout());
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}

export function resetPassword(email: string) {
    return async () => {
        await axios.post('/forgot', {
            email
        });
    }
}

export function logout() {
    return async () => {
        try {
            await axios.post(`/logout`, null);
            const promises = [
                dispatch(resetPlans()),
                dispatch(resetBillingInvoices()),
                dispatch(resetActivityStore()),
                dispatch(resetArticleStore()),
                dispatch(resetBankStore()),
                dispatch(resetBankTransactionStore()),
                dispatch(resetBankRuleStore()),
                dispatch(resetClientStore()),
                dispatch(resetCompanyStore()),
                dispatch(resetCountryStore()),
                dispatch(resetCreditNoteStore()),
                dispatch(resetCurrencyStore()),
                dispatch(resetMicroRevenueStore()),
                dispatch(resetMonthlyStore()),
                dispatch(resetTransactionStore()),
                dispatch(resetYearlyStore()),
                dispatch(resetDeliveryNoteStore()),
                dispatch(resetErrorStore()),
                dispatch(resetExpenseStore()),
                dispatch(resetExpenseTypeStore()),
                dispatch(resetAppIntegrationStore()),
                dispatch(resetInvoiceStore()),
                dispatch(resetMaintenanceStore()),
                dispatch(resetNotificationStore()),
                dispatch(resetPaymentStore()),
                dispatch(resetQuoteStore()),
                dispatch(resetRecurringInvStore()),
                dispatch(resetRecurringExpStore()),
                dispatch(resetUrssafStore()),
                dispatch(resetSupplierStore()),
                dispatch(resetVatAppTaxesStore()),
                dispatch(resetVatStore())]

            await Promise.all(promises);
            // clear local state
            const itemsToRemove = ['articles', 'transactions', 'clients', 'suppliers', 'expenses', 'credit_notes', 'invoices', 'external_payments', 'delivery_notes', 'quotes', 'client_quotes', 'client_invoices', 'payments', 'selectedBank', 'impersonation'];
            itemsToRemove.forEach(item => {
                localStorage.removeItem(item);
            });

            setSession(null);
            dispatch(slice.actions.logout());
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function UpdateProfilePartial(data: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch('/users/me', data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            if (response.data) {
                if (!response.data.roles) response.data.roles = [];
                dispatch(slice.actions.loginSuccess({ user: response.data }));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function changePassword(data: any) {
    return async () => {
        try {
            await axios.post(`/users/me/password`, data)
            return true;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
            return Promise.reject(error);
        }
    }
}

export function updateAccount(data: any) {
    return async () => {
        try {
            const response = await axios.put('/users/me', data)
            if (response.data) {
                if (!response.data.roles) response.data.roles = [];
                dispatch(slice.actions.updateUser(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function createKey(data: any) {
    return async () => {
        try {
            const response = await axios.post(`/users/me/api_keys`, data)
            if (response.data) {
                if (!response.data.roles) response.data.roles = [];
                dispatch(slice.actions.updateUser(response.data));
                return true;
            } else {
                dispatch(slice.actions.endLoading());
                return false;
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function deleteApiKey(keyId: any) {
    return async () => {
        try {
            await axios.delete(`/users/me/api_keys/${keyId}`)
            dispatch(slice.actions.apiKeyDeleted(keyId));
            return true;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function refreshAccountProfile() {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.get('/users/me');
            if (!response.data.roles) response.data.roles = [];
            dispatch(slice.actions.updateUser(response.data));
            const plan = response.data.plan?.split('_') ?? ['essential', 'yearly'];
            dispatch(updateCurrentPlan({ plan: plan[0], interval: plan[1] ?? 'yearly', subscription_status: response.data.subscription_status, billing_provider: response.data.billing_provider }))
            return true;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function changeAccountPlan(plan: string) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.put('/subscriptions/my', { plan });
            await dispatch(refreshAccountProfile());
            dispatch(getBillingInvoices({ limit: 25, offset: 0, sort: 'date' }));
            return { ...response.data, success: true };
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            return {
                success: false,
                error: error.response?.data?.message || 'Failed to change plan'
            };
        }
    }
}

export function cancelSubscription() {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            await axios.post('/subscriptions/my/cancel', {});
            await dispatch(refreshAccountProfile());
            dispatch(slice.actions.endLoading());
            return true;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function removeCancelSubscription() {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            await axios.delete('/subscriptions/my/cancel');
            await dispatch(refreshAccountProfile());
            dispatch(slice.actions.endLoading());
            return true;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function activateSubscription() {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.post('/subscriptions/my/activate');
            await dispatch(refreshAccountProfile());
            dispatch(slice.actions.endLoading());
            return response.data;
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    }
}