// yup
import * as Yup from 'yup';
// react
import { useEffect, useState } from 'react';
// react router
import { useLocation } from 'react-router';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// notifications
import { useSnackbar } from 'notistack';
// lodash
import { omit } from 'lodash';
// hooks
import useLocales from 'src/hooks/useLocales';
import { dispatch } from 'src/redux/store';
// components
import Iconify from 'src/components/iconify';
import { FormProvider, RHFTextField2 } from 'src/components/hook-form';
// redux
import { login, register } from 'src/redux/slices/auth/auth';
import { addNotification } from 'src/redux/slices/notifications/notifications';
// types
import { RegisterBody } from 'src/@types/auth/auth';
// section helper
import { getPropertiesStartWith } from 'src/sections/auth/login/LoginForm';

//---------------------------------------------------------------------------------

export const filterObjectByProperties = (obj: any, propertiesToFilter: string[]) => {
    // Create a new object to store the filtered properties
    const filteredObject: any = {};

    // Loop through the propertiesToFilter array
    for (const property of propertiesToFilter) {
        // Check if the property exists in the original object
        if (obj.hasOwnProperty(property)) {
            // If the property exists, add it to the filtered object
            filteredObject[property] = obj[property];
        }
    }

    // Return the filtered object
    return filteredObject;
}


interface FormValuesProps extends RegisterBody {
    c_password: string;
    afterSubmit?: string;
};

export default function RegisterForm() {

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const queryParamObject = Object.fromEntries(queryParams.entries());

    const { email } = queryParamObject;

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const [showPassword, setShowPassword] = useState(false);

    const [showConfPassword, setShowConfPassword] = useState(false);

    const RegisterSchema = Yup.object().shape({
        email: Yup.string().email(translate('register.error.email_must_valid')).required(translate('register.error.email_required')),
        password: Yup.string().required(translate('register.error.password_require')),
        c_password: Yup.string()
            .nullable()
            .oneOf([Yup.ref('password')], translate('register.error.pass_must_match'))
            .required(translate('register.error.c_password_require')),

    });

    const defaultValues = {
        email: email ?? '',
        password: '',
        c_password: '',
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {

        data.additional_properties = getPropertiesStartWith({ paramObject: queryParamObject, subString: 'prop_' })
        const additional_params = ["subscription_plan", "affiliate", "coupons", "tags", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "subscription_provider"];
        data = { ...data, ...filterObjectByProperties(queryParamObject, additional_params) }

        try {
            const response = await dispatch(register(omit(data, ['c_password'])));

            const { redirect_url } = response;

            if (response.user) {
                await dispatch(login({ email: data.email, password: data.password, additional_properties: {} }, '/login', redirect_url ?? ''))
            }
        } catch (error) {
            console.error(error);
            reset();
            enqueueSnackbar(translate('common.something_wrong'), { variant: 'error' })
        }
    };

    useEffect(() => {
        if (errors.afterSubmit) {
            dispatch(addNotification(errors?.afterSubmit?.message ?? '', 'error'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, dispatch])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <RHFTextField2 name="email" label={translate('register.email_address_input_label')} />

                <RHFTextField2
                    name="password"
                    label={translate('register.password_input_label')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <RHFTextField2
                    name="c_password"
                    label={translate('register.confirm_password_input_label')}
                    type={showConfPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfPassword(!showConfPassword)} edge="end">
                                    <Iconify icon={showConfPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mt: 3 }}
                loading={isSubmitting}
            >
                {translate('register.register_btn')}
            </LoadingButton>
        </FormProvider>
    );
}
