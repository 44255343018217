import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { useEffect, useRef, useState } from 'react';
import { FrillWidget } from 'src/@types/frill/frill';
import useLocales from 'src/hooks/useLocales';


// ----------------------------------------------------------------------

export function NavUpgrade({ sx, ...other }: BoxProps) {

  const { user } = useSelector(state => state.auth);

  const { translate, currentLang: { value: language } } = useLocales();

  // Use component state to show the number of unread announcements
  const [loaded, setLoaded] = useState(false);

  // We need to keep a reference to the widget instance so we interact with it
  const widgetIdeaRef = useRef<FrillWidget>();

  const openIdea = () => {
    // First check that there is a widget
    if (widgetIdeaRef.current) {
      widgetIdeaRef.current.open();
    }
  }

  // This effect will run once when the component has mounted
  useEffect(() => {
    console.log('** Initializing Frill Idea');
    (window?.Frill as any)('widget', {
      key: "c71eb3bf-f0e1-4770-b276-0ce9e93bdf02", // Replace with your widget key
      ssoToken: user?.transient_attributes?.frill_token ?? '',
      language: language,
      callbacks: {
        onReady(widget: any) {
          console.log('Ready idea!')
          widgetIdeaRef.current = widget;
          setLoaded(true);
        },
      },
    });


    return () => {
      console.log('destroying idea')
      if (widgetIdeaRef.current?.destroy) {
        widgetIdeaRef.current.destroy();
        widgetIdeaRef.current = undefined;
        setLoaded(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.transient_attributes?.frill_token, language]);


  return (
    loaded ?
      <Box
        sx={[{ px: 2, py: 5, textAlign: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...other}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Avatar src={'/assets/frill_idea.png'} alt={'Bizyness roadmap'} sx={{ width: 60, height: 60 }}>
            {'Bizyness'}
          </Avatar>
          <Typography variant='subtitle2' sx={{ width: '60%', mt: 1.5 }}>{translate('frill.idea.tag_line')}</Typography>
          <Button variant="contained" color='primary' sx={{ mt: 1.5 }} onClick={openIdea}>
            {translate('frill.idea.view_idea_btn')}
          </Button>
        </Box>
      </Box>
      : null
  );
}
