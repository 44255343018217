import Box, { BoxProps } from '@mui/material/Box';
import { useSettingsContext } from 'src/components/settings';
import { HEADER } from '../config-layout';
import useResponsive from 'src/hooks/useResponsive';
import { isAccountCancelled, isTrailPeriod } from './header';
import { useSelector } from 'src/redux/store';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const { translate } = useLocales();

  const { user } = useSelector(state => state.auth);

  const isTrailPeriodRunning = isTrailPeriod(user, translate);

  const isAccountSuspended = isAccountCancelled(user);

  const settings = useSettingsContext();

  let isDownLg = (useResponsive('only', 'md') && isAccountSuspended) ? 110 : 0;
  let isDownMd = (useResponsive('only', 'sm') && isAccountSuspended) ? 150 : 0;
  let isDownMd1 = (useResponsive('only', 'xs') && isAccountSuspended) ? 170 : 0;

  isDownLg = (useResponsive('only', 'md') && isTrailPeriodRunning) ? isDownLg + 20 : isDownLg
  isDownMd = (useResponsive('only', 'sm') && isTrailPeriodRunning) ? isDownMd + 20 : isDownMd // 135
  isDownMd1 = (useResponsive('only', 'xs') && isTrailPeriodRunning) ? isDownMd1 + 50 : isDownMd1; //  150

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const sum = isDownLg + isDownMd + isDownMd1

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: {
            xs: `${HEADER.H_MOBILE + 24 + sum}px`,
            lg: `${HEADER.H_DESKTOP + 80 + (isAccountSuspended ? 110 : 0) + (isTrailPeriodRunning ? 50 : 0)}px`,
          },
          pb: {
            xs: `${HEADER.H_MOBILE + 24}px`,
            lg: `${HEADER.H_DESKTOP + 24 + 110}px`,
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: {
          xs: `${HEADER.H_MOBILE + 24 + sum}px`,
          lg: `${HEADER.H_DESKTOP + 40 + (isAccountSuspended ? 80 : 0) + (isTrailPeriodRunning ? 50 : 0)}px`,
        },
        py: `${HEADER.H_MOBILE + SPACING}px`,
        pb: {
          xs: `${HEADER.H_MOBILE + 24}px`,
          lg: `${HEADER.H_DESKTOP}px`,
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
