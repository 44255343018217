import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';
// @types
import { IntegrationState } from 'src/@types/integrations/integrations';
import { dispatch } from 'src/redux/store';

const initialState: IntegrationState = {
    isLoading: false,
    error: null,
    data: { integrations: [], total_count: 0 }
};

const slice = createSlice({
    name: 'app_integrations',
    initialState,
    reducers: {
        // START LOADING
        startLoading: (state) => {
            state.isLoading = true;
            return state;
        },

        // HAS ERROR
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            return state;
        },

        // GET EVENTS
        getIntegrationsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            return state;
        },

        // UPDATE
        updateIntegration: (state, action) => {
            state.isLoading = false;
            state.data.integrations = state.data.integrations.map(integration => integration.id === action.payload.id ? action.payload : integration);
            return state;
        },

        // DELETE
        deleteIntegration: (state, action) => {
            state.isLoading = false;
            state.data.integrations = state.data.integrations.filter(integration => integration.id !== action.payload);
            return state;
        },

        // RESET STATE
        resetState: () => initialState
    },
});

// Reducer
export default slice.reducer;

export function getIntegrations(params: any = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/integrations`, { params: params });
            dispatch(slice.actions.getIntegrationsSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function enableIntegration(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/integrations/${id}/enable`);
            dispatch(slice.actions.updateIntegration(response.data));
            return true
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return false;
        }
    };
}

export function disableIntegration(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/integrations/${id}/disable`);
            dispatch(slice.actions.updateIntegration(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteIntegration(id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`/integrations/${id}`);
            dispatch(slice.actions.deleteIntegration(id));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateIntegrationPartial(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.patch(`/integrations/${id}`, data, { headers: { 'Content-Type': 'application/json-patch+json' } });
            dispatch(slice.actions.updateIntegration(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateIntegration(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/integrations/${id}`, data);
            dispatch(slice.actions.updateIntegration(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function initializeIntegration(data: any, id: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/integrations/${id}/initialize`, data);
            dispatch(slice.actions.updateIntegration(response.data));
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reset() {
    return async () => {
        dispatch(slice.actions.resetState())
    }
}