import { memo } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { bgBlur } from 'src/theme/css';

import Scrollbar from 'src/components/scrollbar';
import { NavSectionHorizontal } from 'src/components/nav-section';

import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { useSelector } from 'src/redux/store';
import HeaderShadow from '../common/header-shadow';

// ----------------------------------------------------------------------
type Props = {
  isAccountSuspended: boolean;
  isTrailPeriodRunning: boolean | string;
}
function NavHorizontal({ isAccountSuspended, isTrailPeriodRunning }: Readonly<Props>) {
  const theme = useTheme();

  const { data: company } = useSelector(state => state.company);
  const { data: { notifications } } = useSelector(state => state.app_notifications);

  const navData = useNavData(company, !!notifications?.length);


  return (
    <AppBar
      component="div"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
        ...(isAccountSuspended && { top: 165 }),
        ...(isTrailPeriodRunning && { top: 95 }),
        ...(isAccountSuspended && isTrailPeriodRunning && { top: 199 }),
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Scrollbar
          sx={{
            '& .simplebar-content': {
              display: 'flex',
            },
          }}
        >
          <NavSectionHorizontal
            data={navData}
            slotProps={{
              // currentRole: user?.role,
            }}
            sx={{
              ...theme.mixins.toolbar,
            }}
          />
        </Scrollbar>
      </Toolbar>

      <HeaderShadow />
    </AppBar >
  );
}

export default memo(NavHorizontal);
