const en = {
    you_are_logout: "You have been logged out",
    maintenance: {
        heading: "Sorry, Bizyness is experiencing some difficulties",
        subheading: "We are doing what is necessary to restore service as soon as possible.",
    },
    layout_setting: {
        settings: "Settings",
        mode: "Mode",
        contrast: "Contrast",
        layout: "Layout",
        stretch: "Stretch",
        stretch_tooltip: "Only available at large resolutions > 1600px (xl)",
        presets: "Presets",
        fullscreen: "Fullscreen",
        exit_fullscreen: "Exit Fullscreen",
    },
    version: {
        version: "New Version Available!",
        reload: "Reload now"
    },
    data_grid_pro: {
        unsort: "Unsort",
        sort_by_asc: "Sort by ASC",
        sort_by_desc: "Sort by DESC",
        filter: "Filter",
        hide: "Hide",
        show_columns: "Show columns",
        pin_to_left: "Pin to left",
        pin_to_right: "Pin to right",
        unpin: "Unpin",
        show_all: "Show All",
        hide_all: "Hide All",
        find_column: "Find column",
        column_title: "Column title",
        columns: "Columns",
        operator: "Operator",
        value: "Value",
        add_filter: "Add Filter",
        filter_value: "Filter value",
        contains: "contains",
        equals: "equals",
        starts_with: "starts with",
        ends_with: "ends with",
        is: "is",
        is_not: "is not",
        is_after: "is after",
        is_on_or_after: "is on or after",
        is_before: "is before",
        is_on_or_before: "is on or before",
        is_empty: "is empty",
        is_not_empty: "is not empty",
        is_any_of: "is any of",
        and: "And",
        or: "Or",
        menu: "Menu",
        sort: "Sort",
        delete: "Delete",
        no_results_found: "No results found.",
        total_rows: "Total Rows:",
        rows_selected: "rows selected",
        row_selected: "row selected",
        error_occur: "Something went wrong",
        rows_per_page: "Rows per page",
        no_rows: "No rows",
        manage_columns: "Manage columns",
    },
    nav_menu: {
        primary: {
            dashboard: "Dashboard",
            invoices: "Invoices",
            quotes: "Quotes",
            costs: "Costs",
            banks: "Banks",
            contacts: "Contacts",
            articles: "Articles",
            integrations: "Integrations",
            reports: "Reports",
            settings: "Settings",
            notifications: "Notifications",
        },
        secondary: {
            profile: "Profile",
            logout: "Logout"
        }
    },
    login: {
        page_title: "Login",
        form_heading: "Sign In",
        form_subheading: "Enter your details below",
        email_address_input_label: "Email address",
        password_input_label: "Password",
        account_input_label: "Account",
        forget_password_link: "Forget password?",
        dont_have_account_label: "Don't have an account?",
        get_started_link: "Get started",
        connect_btn: "Connect",
        users_say_about_app_heading: "Welcome on Bizyness",
        confirm_heading: "Confirmation code",
        confirm_subheading: "To increase the security of your account a confirmation code has been sent to your email address. Copy the code received into the field below to continue.",
        confirm_code_input_label: "Confirmation code",
        continue_btn: "Continue",
        code_sent: "Confirmation code sent to {{email}}",
        error: {
            email_password_incorrect: "email or password incorrect!",
            email_must_valid: "Email must be a valid email address",
            email_required: "Email is required",
            password_require: "Password is required",
            account_require: "Account is required",
            invalid_code: "Invalid code",
            code_required: "Confirmation code is required",
        }
    },
    forget_password: {
        page_title: "Forget password",
        form_heading: "Forget your password?",
        form_sub_heading: "Enter your email to receive a new password. Don't forget to check your spam folder.",
        back_sign_in_link: "Back to sign in",
        password_sent_alert: "New password sent by email",
        email_address_input_label: "Email address",
        reset_btn: "Reset",
        error: {
            email_must_valid: "Email must be a valid email address",
            email_required: "Email is required",
        }
    },
    register: {
        page_title: "Register",
        form_heading: "Get started",
        form_subheading: "Enter your details below",
        already_have_account_label: "Already have an account?",
        login_link: "Login",
        manage_more_effectively_heading: "Manage more effectively with Bizyness",
        terms_service_link: "Terms of Service",
        privacy_policy_link: "Privacy Policy.",
        email_address_input_label: "Email address",
        password_input_label: "Password",
        confirm_password_input_label: "Confirm password",
        by_sign_up: "By signing up, I agree to ",
        and: " and ",
        register_btn: "Register",
        error: {
            email_must_valid: "Email must be a valid email address",
            email_required: "Email is required",
            password_require: "Password is required",
            pass_must_match: "Passwords must match",
            c_password_require: "Confirm password is required",
        }
    },
    onboarding: {
        page_title: "Onboarding",
        logout_link: "Logout",
        welcome_family_heading: "Welcome to the Bizyness family!",
        welcome_good_choice_subheading: "You made a good choice by joining Bizyness 🎉",
        onboarding_need_info_subheading: "We need some information to configure your account. No worries it will be quick !",
        updated: "Updated successfully",
        update_error: "Update fail",
        already_upto_date: "Already up-to-date",
        validate_btn: "Validate",
        next_btn: "Next",
        previous_btn: "Previous",
        step_identity: {
            title: "Identity",
            who_are_you_label: "Who are you?",
            first_name_input_label: "First name",
            last_name_input_label: "Last name",
            error: {
                first_name: "First name is required",
                last_name: "Last name is required",
            }
        },
        step_status: {
            title: "Status",
            status_of_company_heading: "Status of your company",
            eligible_vat_heading: "Are you eligible to VAT?",
            since_when_heading: "Since when?",
            liability_date_input_label: "Liability date",
            company_status: {
                micro: "Auto-entreprise",
                ei: "EI/EIRL",
                sarl: "SARL/EURL",
                sas: "SAS/SASU",
                other: "Other"
            },
            error: {
                status: "Status is required",
                date: "Date is required",
                invalid_date: "Date is invalid",
            },
            vat_labels: {
                yes: "Yes",
                no: "No"
            },
            when_labels: {
                company_creation: "Since creation of the company",
                another_date: "Since another date"
            },
        },
        step_address: {
            title: "Address",
            address_input_label: "Address",
            complementary_address_input_label: "Complementary address",
            zip_input_label: "Zip",
            city_input_label: "City",
            country_input_label: "Country",
            address_eirl: "Address of your {{company}}",
            company_sm: "company",
            choose_a_country: "Choose a country",
            error: {
                address: "Address is required",
                city: "City is required",
                zip: "Zip is required",
                country: "Country is required",
            },
        },
        step_company: {
            title: "Company",
            information_of: "Information of your {{company}}",
            legal_name_input_label: "Legal name",
            share_capital_input_label: "Share capital",
            siren_input_label: "Siren",
            vat_number_heading_input_label: "VAT number",
            registration_status_input_label: "Registration status",
            company_sm: "company",
            error: {
                legal_name: "Name is required",
                registration_type: "Registration status required",
                registration_detail: "Registration number required",
                must_allowed: "Min {{amount}} allowed",
                share_capital: "Share capital required",
            },
            registration_type_labels: {
                rcs: "City of registration RCS",
                rm: "RM registration department number",
                rseirl: "City of registration RSEIRL",
                rcs_rm: "City of RCS registration and RM registration department number (separated by a comma)"
            },
            registration_types: {
                rcs: "RCS",
                exempted: "Exempted",
                rm: "RM",
                rseirl: "RSEIRL",
                rcs_rm: "Dual registration RCS/RM"
            },
        },
        step_urssaf: {
            title: "URSSAF",
            information_of_urssaf: "Information of your URSSAF",
            creation_date_input_label: "Creation date",
            frequency_tax_pay: "Frequency of tax payment",
            tax_discharge: "Tax discharge",
            beneficiary_acre: "Beneficiary of ACRE",
            distribution_labels: {
                monthly: "Monthly",
                quarterly: "Quarterly",
            },
            tax_discharge_labels: {
                yes: "Yes",
                no: "No"
            },
            beneficiary_labels: {
                yes: "Yes",
                no: "No"
            },
            error: {
                date: "Date is required",
                invalid_date: "Date is invalid",
            }
        },
        step_logo: {
            title: "Logo",
            identity: "Identity",
            delete_btn: "Delete",
            trade_name_input_label: "Trade name",
            email_input_label: "Email",
            footer_input_label: "Footer",
            alert_onboarding: "You can update those elements later in order to personalize the information displayed in your quotes and invoices",
            languages: {
                french: "French",
                english: "English",
                german: "German",
                italian: "Italian",
                spanish: "Spanish"
            },
            error: {
                email: "Invalid email"
            }
        },
        step_summary: {
            title: "Summary",
            recap: "Recap",
            status: "Status",
            legal_name: "Legal name",
            address: "Address",
            registration_status: "Registration status",
            siren: "Siren",
            vat_number_heading: "VAT number",
            share_capital: "Share capital",
        },
    },
    account_suspended: {
        heading: "Your account is suspended",
        description: "You won't be able to create any new invoice or other documents. Click the button to activate your account",
        activate_account_btn: "Activate my account",
    },
    trial: {
        ends_in: "Your trial ends",
        today: "today.",
        remaining: " in {{d}} days.",
        upgrade: "Choose a plan to continue using Bizyness",
        expired: "Your trial has ended.",
        view_plan_btn: "View plans",
    },
    profile: {
        account_heading: "Account",
        general: {
            title: "General",
            update_account_btn: "Update account",
            email_input_label: "Email",
            first_name_input_label: "First name",
            last_name_input_label: "Last name",
            updated: "Updated successfully",
            error: {
                first_name: "First name is required",
                last_name: "Last name is required",
                email_invalid: "Email is invalid",
                email: "Email is required",
            },
        },
        billing: {
            title: "Billing",
            another_billing_provider: {
                shopify: "You are charged by Shopify, connect to your Shopify account for more details"
            },
            subscription_status: {
                trial: "Your trial will end on {{date}}",
                active: "Your subscription will renew on {{date}}",
                non_renewing: "Your subscription will terminate on {{date}}",
                cancelled: "Your subscription is cancelled",
                null: "",
            },
            table: {
                columns: {
                    date: "Date",
                    amount: "Amount",
                    download: "Download Invoice"
                }
            },
            cancel_btn: "Cancel Account",
            remove_cancellation_btn: "Remove account cancellation",
            activate_account_btn: "Activate account",
            change_plan: "View plans",
            update_payment_btn: "Update payment method",
            current_plan: "Current plan",
            invoice_history: "Invoice history",
            free: "Free",
            yearly: "Yearly",
            monthly: "Monthly",
            active_popup: {
                heading: "Cancel subscription",
                subheading: "Your subscription will be cancelled at the end of the current billing period, would you like to continue?",
                cancel_btn: "No",
                confirm_btn: "Yes"
            },
            cancelled_popup: {
                heading: "Activate subscription",
                subheading: "By activating your subscription you will be immediately charged the corresponding amount, would you like to continue?",
                cancel_btn: "No",
                confirm_btn: "Yes"
            },
            non_renewing_popup: {
                heading: "Reactivate subscription",
                subheading: "Your subscription will resume at the next billing date, would you like to continue?",
                cancel_btn: "No",
                confirm_btn: "Yes"
            }
        },
        change_password: {
            title: "Change password",
            updated: "Updated successfully",
            wrong_password: "Wrong password",
            change_password_btn: "Change password",
            old_password_input_label: "Old Password",
            new_password_input_label: "New Password",
            confirm_new_password_input_label: "Confirm New Password",
            error: {
                old_password: "Old password is required",
                new_password: "New Password is required",
                password_8_char: "Password must be at least 8 characters",
                password_should_different: "New password must be different than old password",
                password_must_match: "Passwords must match",
            }
        },
        team: {
            title: "Team",
            active_members: "Active members",
            pending_invitations: "Pending invitations",
            deleted: "Deleted",
            updated: "Updated",
            resent: "Resent",
            enabled: "Enabled",
            disabled: "Disabled",
            columns: {
                username: "User name",
                email: "Email",
                access: "Role",
                state: "Status",
                action: "Actions",
            },
            edit_menu: {
                edit: "Edit",
                delete: "Delete",
                resend: "Resend",
                enable: "Enable",
                disable: "Disable",
            },
            delete_popup: {
                heading: "Warning",
                description: "Are you sure you want to delete the member?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            status_labels: {
                enabled: "Enabled",
                disabled: "Disabled",
                pending: "Pending",
            },
            access: {
                full: "Full access",
                specified: "Restricted access"
            },
            not_found: {
                heading: "You don’t have any team members yet",
                subheading: "Invite first member to give him access and  add to your team",
                invite_btn: "Invite Member"
            },
            invite_popup: {
                heading: "Invite member",
                subheading: "Invite member and choose an access for him",
                first_name: "First name",
                last_name: "Last name",
                email: "Email",
                invited: "Invite sent",
                saved: "Saved",
                access: {
                    full: "Full access",
                    specified: "Restricted access"
                },
                error: {
                    first_name: "First name is required",
                    last_name: "Last name is required",
                    email_must_valid: "Invalid email",
                    email_required: "Email is required",
                    roles: "At least one role required",
                },
                permission_heading: "Choose the permissions: ",
                permission_labels: {
                    features: {
                        invoices: "Invoices",
                        quotes: "Quotes",
                        delivery_forms: "Delivery forms",
                        payments: "Payments",
                        expenses: "Expenses",
                        clients: "Clients",
                        suppliers: "Suppliers",
                        articles: "Articles",
                        banks: "Banks",
                        activities: "Activities",
                        integrations: "Integrations",
                        notifications: "Notifications",
                        report: "Reports",
                        expense_type: "Expense types",
                    },
                    permissions: {
                        invoices_read: "Read",
                        invoices_write: "Write",
                        invoices_send: "Send",
                        invoices_export: "Export",
                        quotes_read: "Read",
                        quotes_write: "Write",
                        quotes_send: "Send",
                        quotes_bill: "Bill",
                        delivery_forms_read: "Read",
                        delivery_forms_write: "Write",
                        delivery_forms_send: "Send",
                        payments_read: "Read",
                        payments_write: "Write",
                        payments_export: "Export",
                        expenses_read: "Read",
                        expenses_write: "Write",
                        expenses_export: "Export",
                        clients_read: "Read",
                        clients_write: "Write",
                        clients_export: "Export",
                        suppliers_read: "Read",
                        suppliers_write: "Write",
                        articles_read: "Read",
                        articles_write: "Write",
                        articles_export: "Export",
                        banks_read: "Read",
                        banks_write: "Write",
                        activities_write: "Write",
                        integrations_read: "Read",
                        integrations_write: "Write",
                        notifications_write: "Write",
                        report_revenues: "Revenues",
                        report_taxes: "Taxes report",
                        report_accounting: "Accounting",
                        report_ioss: "OSS/IOSS",
                        expense_types_write: "Write",
                    }
                },
                cancel_btn: "Cancel",
                invite_btn: "Invite",
                save_btn: "Save",
            }
        },
        api_keys: {
            title: "API keys",
            create_key: "Create a key",
            copied: "Copied!",
            delete_popup: {
                heading: "Warning",
                description: "Are you sure you want to delete the key?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
                api_key_deleted: "Api key deleted",
            },
            create_popup: {
                title: "API key creation",
                subtitle: "Allow third-party applications to access your Bizyness account",
                key_name_input_label: "Key name",
                write_permission: "Write permission",
                create_key: "Create a key",
                allow_inv_quo_cbx: "Allow the app to create invoices and quotes",
                cancel_btn: "Cancel",
                create_btn: "Create",
                key_created: "Key created",
                error: {
                    name: "Name is required",
                },
            }
        },
        account_deletion: {
            title: "Account deletion",
            heading: "Account deletion",
            subheading: "This operation cannot be reversed and will result in the immediate cancellation of your subscription.",
            delete_my_account_btn: "Delete my account",
            confirm_popup: {
                cancel_btn: "Cancel",
                delete_my_account_btn: "Delete my account",
                deleted: "Account deleted!",
                wrong_password: "Wrong password",
                heading: "Account deletion",
                description: "All your account data will be deleted (invoices, expenses, etc.), this operation cannot be reversed. If you wish to continue, please enter your password and confirm.",
                confirm_your_pass: "Confirm your password",
                password_input_label: "Password",
            }
        },
    },
    dashboard: {
        dashboard: "Dashboard",
        urssaf: {
            declaration: "Declaration of",
            quarter: "Quarter",
            to_declare_before: "To declare before",
            revenue_of_distance_sales: "Revenue of distance sales:",
            revenue_of_service_deliveries: "Revenue of service deliveries:",
            revenue_of_other_services: "Revenue of other services:",
            estimated_taxes: "Estimated taxes",
        },
        monthly_report: {
            figures_of: "Figures of ",
            incomes: "Incomes",
            expenses: "Expenses",
            net_margin: "Net Margin",
            than_last_month: "compared to last month",
        },
        yearly_report: {
            turnover: "Turnover",
            than_previous_year: "compared to previous year",
            micro_threshold: "Micro threshold",
            vat_threshold: "Vat threshold",
            incomes: "Incomes",
            expenses: "Expenses",
            net_margin: "Net Margin",
            services: "Services",
        },
        summary: {
            overdue_invoices: "Overdue invoices",
            pending_invoices: "Pending invoices",
            pending_quotes: "Pending quotes",
            no_pending_invoices: "You have no pending invoices",
            no_overdue_invoices: "You have no overdue invoices",
            no_pending_quotes: "You have no pending quotes",
            see_more: "See more",
            invoices: "Invoices",
            quotes: "Quotes",
        },
        welcome: {
            welcome: "Welcome",
            benefits: "Benefits",
            bank_transactions: "Bank transactions",
            no_more_records: "No more records!",
            see_all: "See all",
        },
    },
    invoices: {
        nav_title: "Invoices",
        list: {
            heading: "Invoices",
            import_btn: "Import",
            new_invoice_btn: "New invoice",
            export_popup: {
                error: {
                    invalid_email: "Invalid email",
                    email: "Email address is required",
                },
                export: "Export",
                export_msg: "The report will be sent into your email box within few minutes",
                export_as_pdf: "Export as PDF file",
                export_as_csv: "Export as CSV file",
                heading: "Export invoices",
                sub_heading: "Select a period to download an extract of your invoices",
                export_x_invoice: "Export {{count}} invoices",
                date_filters: {
                    today: "Today",
                    yesterday: "Yesterday",
                    this_week: "This week",
                    last_week: "Last week",
                    this_month: "This month",
                    last_month: "Last month",
                    last_30_days: "Last 30 days",
                    last_90_days: "Last 90 days",
                    last_12_months: "Last 12 months",
                    this_year: "This year",
                    last_year: "Last year",
                },
                reference_date_options: {
                    date_of_issue: "Issue date",
                    date_of_sale: "Sale date",
                    payment_date: "Paid date",
                },
                billing_period: "Billing period",
                more_filters: "More filters",
                less_filters: "Less filters",
                from_input_label: "From",
                to_input_label: "To",
                loading: "Loading...",
                not_found: "No country found",
                country_input_label: "Country",
                no_activity_found: "No activity found",
                activity_input_label: "Activity",
                filter_by: "Filter by",
                attachments: "Attachments",
                include_attachments: "Include Attachments",
                recipient: "Recipient",
                email_input_label: "Email",
                cancel_btn: "Cancel",
                export_limit_alert: "You can't export more than {{limit}} elements at a time, please adjust the filters and period",
                pdf_merged_label: "Group invoices in one single file",
            },
            import_popup: {
                success: "Success",
                heading: "Import invoices",
                sub_heading: "Select your file containing the list of invoices to import",
                validate_btn: "Validate",
                import_alert: "To learn more about the steps to follow, please read this article:",
                cancel_btn: "Cancel",
                drop_csv_here: "Drop your CSV file here",
                create_drafts: "Create drafts",
                price_indicated_vat: "Prices indicated incl. VAT",
                issue_drafts_labels: {
                    yes: "Yes",
                    no: "No",
                },
                taxes_included_labels: {
                    yes: "Yes",
                    no: "No",
                }
            },
            all_activity: "All",
            deleted: "deleted",
            not_found: {
                heading: "Record your invoice information here",
                sub_heading: "The detailed view brings together all the key information for your business",
            },
            reference_date: "Reference date:",
            tabs: {
                all: "All",
                paid: "Paid",
                not_paid: "Not paid",
                overdue: "Overdue",
                cancelled: "Cancelled",
                draft: "Draft",
            },
            columns: {
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date",
                    paid_date: "Paid date",
                    execution_date: "Execution date",
                    refund_date: "Refund date",
                },
                date: "Date",
                reference: "Reference",
                client: "Client",
                subject: "Subject",
                total_exc_taxes: "Total Excl. Taxes",
                total_inc_taxes: "Total Incl. Taxes",
                status: "Status",
                status_tag: {
                    paid: "Paid",
                    cancelled: "Cancelled",
                    overdue: "Overdue",
                    draft: "Draft",
                    not_paid: "Not paid",
                    null: ""
                },
            },
            search_client_or_invoices: "Search client or invoice number",
            delete_popup: {
                heading: "Are you sure?",
                description: "Invoices will be deleted or voided",
                no_btn: "No",
                yes_btn: "Yes"
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date",
                    paid_date: "Paid date",
                    execution_date: "Execution date",
                    refund_date: "Refund date",
                },
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
            selected: "Selected",
            delete: "Delete",
            summary: {
                all: "All",
                paid: "Paid",
                not_paid: "Not paid",
                overdue: "Overdue",
                cancelled: "Cancelled",
                draft: "Draft",
                invoices: "Invoices",
            }
        },
        view: {
            deleted: "Deleted",
            duplicated: "Duplicated",
            created_delivery_form: "Delivery form created",
            issued: "Issued",
            view_invoice: "View invoice",
            edit: "Edit",
            more_actions: "More actions",
            edit_menu: {
                modify_invoice: "Modify invoice",
                duplicate_invoice: "Duplicate invoice",
                create_delivery_form: "Create a delivery form",
            },
            delete: "Delete",
            void: "Void",
            pay: "Pay",
            finalize: "Finalize",
            finalize_popup: {
                heading: "Finalize invoice?",
                description: "The issued invoice won't be editable any longer, are you sure?",
                cancel_btn: "Cancel",
                finalize_btn: "Finalize",
            },
            status_tag: {
                paid: "Paid",
                cancelled: "Cancelled",
                overdue: "Overdue",
                draft: "Draft",
                not_paid: "Not paid",
                null: ""
            },
            delete_popup: {
                heading: "Delete Invoice",
                void_heading: "Void the invoice?",
                description: "Do you want to delete this invoice?",
                void_description: "A credit note will be created to void this invoice",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
                create_credit_note_btn: "Issue a credit note",
            },
            summary: {
                client: "Client",
                total_excl_vat: "Total excl. VAT",
                total_incl_vat: "Total incl. VAT",
                date: "Date",
                overview: "Overview",
                delay_expiration: "Delay before expiration",
                days: "Days",
                urssaf_taxes: "URSSAF taxes",
                status_of_payments: "Payments",
                sales_overview: "Sales overview",
                warning_overdue_days: "Warning! Payment is now overdue by {{days}} days!",
                warning_overdue_day: "Warning! Payment is now overdue by {{day}} day!",
                delay_before_due_date: "Deadline",
                overdue: "Overdue",
            },
            toolbar: {
                view_detail_options: {
                    events: "Events",
                    payments: "Payments",
                    credit_notes: "Credit notes",
                    attachments: "Attachments",
                },
                download: "Download",
                print: "Print",
                send: "Send",
                hide_notes: "Hide notes",
                notes: "Notes",
                show_more: "Show more",
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                }
            },
            payment_modal: {
                transaction_link_desc: "This income is linked to a bank transaction and some elements cannot be modified",
                error: {
                    amount: "Amount is required",
                    date: "Date is required",
                    method: "Method is required",
                    invoice: "Invoice is required",
                    amount_min_max: "Allowed between {{min}} to {{max}}",
                },
                create_payment_success: "The payment has been created",
                update_payment_success: "The payment has been updated",
                invoice_to_xxx: "Invoice to {{name}}",
                payment: "Payment",
                loading: "Loading...",
                no_invoice: "No invoice found",
                invoice_to_paid: "Invoice to be paid",
                date: "Date",
                amount: "Amount",
                label: "Label",
                payment_mode: "Payment mode",
                notes: "Notes",
                note_by_cheque: "Note on cheque, client, ...",
                cancel_btn: "Cancel",
                view_invoice_btn: "View invoice",
                view_credit_note_btn: "View credit note",
                save_btn: "Save",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                }
            },
            more_details_panel: {
                events: {
                    history: "History",
                    timeline_of_events: "Timeline of events",
                },
                payments: {
                    delete_payment_success: "The payment has been deleted",
                    certificate_name: "payments_certificate_{{name}}.pdf",
                    downloaded: "Downloaded",
                    download_statements: "Download payments statement",
                    payments: "Payments",
                    payment_list: "List of payments attached to this invoice",
                    payment_methods: {
                        bank_transfer: "Bank transfer",
                        cash: "Cash",
                        check: "Check",
                        cesu: "Cesu",
                        direct_debit: "Direct debit",
                        credit_card: "Credit card",
                        other: "Other",
                        paypal: "PayPal",
                        gift_card: "Gift card",
                        null: ""
                    },
                    delete_popup: {
                        heading: "Delete Payment?",
                        description: "This operation cannot be reversed!",
                        cancel_btn: "Cancel",
                        delete_btn: "Delete",
                    }
                },
                credit_notes: {
                    credit_notes: "Credit notes",
                    list_credit_notes_invoice_attached: "List of credit notes attached to this invoice",
                },
                attachments: {
                    attachments: "Attachments",
                    select_files: "Select files",
                },
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            edit_popup: {
                error: {
                    activity: "Activity is required",
                    title: 'Title is required',
                    quantity: 'Required',
                    country: "Country is required",
                    price: "Price is required",
                    payment_gateway: "Payment gateway required",
                },
                modify_invoice: "Modify invoice",
                updated: "Updated",
                sales_type: "Sales type",
                country_of_origin: "Country of origin",
                choose_a_country: "Choose a country",
                tracking_number: "Tracking number",
                purchase_price: "Purchase price",
                activity: "Activity",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
                cancel_btn: "Cancel",
                save_btn: "Save",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateways",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
            },
        },
        edit: {
            alert_info: "Once issued, an accounting document cannot be modified or deleted in order to respect the chronological numbering of the documents. However, you can modify certain information to adjust the calculation of your contributions and taxes. To rectify this invoice, you must cancel it by sending your customer a credit note and issuing a new invoice.",
            status_tag: {
                paid: "Paid",
                cancelled: "Cancelled",
                overdue: "Overdue",
                draft: "Draft",
                not_paid: "Not paid",
                null: ""
            },
            new_invoice: "New invoice",
            error: {
                date: "Date is required",
                invalid_date: "Date is invalid",
                language: "Language is required",
                currency: "Currency is required",
                activity: "Activity is required",
                template: "Template is required",
                last_name: "Last name is required",
                country: "Country is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                client: "Client is required",
                zip: "Zip is required",
                title: 'Title is required',
                quantity: 'Required',
                price: "Price is required",
                price_min: "Minimum price allowed {{amount}}",
                tree: 'At least 1 article is required',
                payment_gateway: "Payment gateway required",
                tax_rate: "Required",
            },
            restore_version: "You have unsaved elements",
            draft_actions: {
                apply: "Restore",
                discard: "Ignore",
            },
            created: "Invoice created",
            updated: "Invoice updated",
            saved: "Saved",
            cancel_btn: "Cancel",
            save_btn: "Save",
            confirm_rule_popup: {
                heading: "Update of VAT",
                description: "Rules of taxes have changes, do you want to apply them",
                yes_btn: "Yes",
                no_btn: "No",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "Taxes incl.",
                    vat_excl: "Taxes excl.",
                },
                vat_labels: {
                    no: "No",
                    yes: "Yes",
                },
                vat_exempt: "VAT exempt",
                price_format: "Price Format",
                exemption_text: "Exemption Text",
                exemption_placeholder: "Statement...",
            },
            filter_section: {
                language_input_label: "Language",
                currency_input_label: "Currency",
                activity_input_label: "Activity",
                select_template_input_label: "Template",
                subject_input_label: "Subject",
                invoice_title: "Invoice title...",
                expiration_date_input_label: "Expiration date",
                order_date_input_label: "Order date",
            },
            pdf_section: {
                loading_pdf: "Loading PDF",
                of: "of",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Choose a client",
                no_client_found: "No Client Found",
                use_another_shipping: "Use another shipping address",
                another_shipping: {
                    company_input_label: "Company",
                    vat_number_input_label: "VAT number",
                    registration_number_input_label: "Registration number",
                    last_name_input_label: "Last name",
                    first_name_input_label: "First name",
                    address_input_label: "Address",
                    address_supplementary_input_label: "Address supplementary",
                    zip_input_label: "Zip",
                    city_input_label: "City",
                    country_input_label: "Country",
                    client_type_labels: {
                        individual: "Individual",
                        business: "Company",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "No Article Found",
                loading: "Loading...",
                choose_article: "Choose article",
                search_articles: "Search article...",
                edit_line: "Edit line",
                add_new_line: "Add a new line",
                edit_popup: {
                    ref: "REF",
                    title: "Title",
                    description: "Description",
                    insert_tag: "Insert a tag:",
                    tags: {
                        start_date: "Start date",
                        end_date: "End date",
                        start_month: "Start month",
                        end_month: "End month",
                        start_year: "Start year",
                        end_year: "End year",
                    },
                    amount: "Amount",
                    vat: "VAT",
                    no_results_found: "No results found.",
                    discount: "Discount",
                    qte: "QTE",
                    unit: "Unit",
                    sales_type: "Sales type",
                    country_of_origin: "Country of origin",
                    choose_a_country: "Choose a country",
                    tracking_number: "Tracking number",
                    second_hand_good: "Second hand good",
                    purchase_price: "Purchase price",
                    save_article: "Save article",
                    save_btn: "Save",
                    cancel_btn: "Cancel",
                    unit_labels: {
                        piece: "Piece",
                        hour: "Hour",
                        day: "Day",
                        meter: "Meter",
                        kilometer: "Kilometer",
                        gram: "Gram",
                        kilogram: "Kilogram",
                        square_meter: "Square meter",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Discount/Shipping cost",
                discount: "Discount",
                shipping_cost: "Shipping cost",
                vat: "VAT",
                no_results_found: "No results found.",
            },
            payment_section: {
                payment: "Payment",
                payment_method: "Payment method",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateway",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                payment_delay: "Payment delay",
                penalty_rate: "Penalty rate",
                rebate: "Rebate",
                payment_terms: "Payment terms",
                payment_delay_labels: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: 'None',
                }
            },
            option_section: {
                options: "Options",
                comments: "Comments",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
            },
            attachment_section: {
                attachments: "Attachments",
                latest_added_attachments: "Latest Added Attachments",
            }
        },
    },
    invoice: {
        history: {
            email: "Invoice sent to {{email}}",
            emailSent: "Invoice sent to {{email}}",
            paymentAdded: "Payment of {{currency}}{{total}} added",
            paymentRemoved: "Payment of {{currency}}{{total}} deleted",
            issued: "Invoice issued",
            backIssued: "Invoice not paid",
            cancelled: "Invoice cancelled",
            asset: "A credit note has been issued",
            created: "Invoice issued",
            paid: "Invoice paid",
            issuedAsset: "Credit note issued",
            paymentGift: "Gift card of {{currency}}{{total}} used",
            emailRead: "Invoice read"
        },
    },
    recurring_invoices: {
        nav_title: "Recurring",
        list: {
            heading: "Recurring invoices",
            new_invoice_btn: "New recurring invoice",
            deleted: "Recurring invoice deleted",
            delete_fail: "Unable to delete",
            disabled: "Disabled",
            enabled: "Enabled",
            not_found: {
                heading: "Recurring invoices information here",
                sub_heading: "Invoices will be automatically issued with the information indicated, on the scheduled date",
            },
            columns: {
                client: "Client",
                frequency_title: "Frequency",
                duration_title: "Duration",
                next_inv: "Next invoice",
                amount_title: "Total incl. taxes",
                status: "Status",
            },
            delete_popup: {
                heading: "Warning",
                description: "Are you sure you want to delete the invoice?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            edit_menu: {
                disable: "Disable",
                enable: "Enable",
                modify: "Modify",
                delete: "Delete",
                edit: "Edit",
            },
            frequency: {
                weekly: "Weekly",
                monthly: "Monthly",
                quarterly: "Quarterly",
                yearly: "Yearly",
                custom: "Custom",
            },
            duration: {
                not_limited: "Not limited",
                stop_after_date: "Stop after a date",
                until: "Until"
            },
            status_labels: {
                enabled: "Active",
                disabled: "Inactive"
            },
        },
        edit: {
            status_tag: {
                paid: "Paid",
                cancelled: "Cancelled",
                overdue: "Overdue",
                draft: "Draft",
                not_paid: "Not paid",
                null: ""
            },
            new_invoice: "New recurring invoice",
            error: {
                date: "Date is required",
                invalid_date: "Date is invalid",
                language: "Language is required",
                currency: "Currency is required",
                activity: "Activity is required",
                template: "Template is required",
                last_name: "Last name is required",
                country: "Country is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                client: "Client is required",
                zip: "Zip is required",
                title: 'Title is required',
                quantity: 'Required',
                price: "Price is required",
                price_min: "Minimum price allowed {{amount}}",
                tree: 'At least 1 article is required',
                min_start_date: "End date must be after start date",
                occurrence_type: "Type is required",
                occurrence_value: "Value is required",
                payment_gateway: "Payment gateway required",
                tax_rate: "Required",
            },
            restore_version: "You have unsaved elements",
            draft_actions: {
                apply: "Restore",
                discard: "Ignore",
            },
            created: "Invoice created",
            updated: "Invoice updated",
            saved: "Saved",
            cancel_btn: "Cancel",
            save_btn: "Save",
            confirm_rule_popup: {
                heading: "Update of VAT",
                description: "Rules of taxes have changes, do you want to apply them",
                yes_btn: "Yes",
                no_btn: "No",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "Taxes incl.",
                    vat_excl: "Taxes excl.",
                },
                vat_labels: {
                    no: "No",
                    yes: "Yes",
                },
                vat_exempt: "VAT exempt",
                price_format: "Price Format",
                exemption_text: "Exemption Text",
                exemption_placeholder: "Statement...",
            },
            setting_section: {
                frequency: {
                    weekly: "Weekly",
                    monthly: "Monthly",
                    quarterly: "Quarterly",
                    yearly: "Yearly",
                    custom: "Custom",
                },
                duration: {
                    not_limited: "Not limited",
                    stop_after_date: "Stop after a date",
                    until: "Until"
                },
                dwmy: {
                    day: "Days",
                    week: "Week",
                    month: "Month",
                    year: "Year",
                },
                settings: "Settings",
                date_first_invoice: "Date of 1st invoice",
                frequency_title: "Frequency",
                duration_title: "Duration",
                send_invoice: "Send invoice",
            },
            filter_section: {
                language_input_label: "Language",
                currency_input_label: "Currency",
                activity_input_label: "Activity",
                select_template_input_label: "Template",
                subject_input_label: "Subject",
                invoice_title: "Invoice title...",
                expiration_date_input_label: "Expiration date",
                order_date_input_label: "Order date",
            },
            pdf_section: {
                loading_pdf: "Loading PDF",
                of: "of",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Choose a client",
                no_client_found: "No Client Found",
                use_another_shipping: "Use another shipping address",
                another_shipping: {
                    company_input_label: "Company",
                    vat_number_input_label: "VAT number",
                    registration_number_input_label: "Registration number",
                    last_name_input_label: "Last name",
                    first_name_input_label: "First name",
                    address_input_label: "Address",
                    address_supplementary_input_label: "Address supplementary",
                    zip_input_label: "Zip",
                    city_input_label: "City",
                    country_input_label: "Country",
                    client_type_labels: {
                        individual: "Individual",
                        business: "Company",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "No Article Found",
                loading: "Loading...",
                choose_article: "Choose article",
                search_articles: "Search article...",
                edit_line: "Edit line",
                add_new_line: "Add a new line",
                edit_popup: {
                    ref: "REF",
                    title: "Title",
                    description: "Description",
                    insert_tag: "Insert a tag:",
                    tags: {
                        start_date: "Start date",
                        end_date: "End date",
                        start_month: "Start month",
                        end_month: "End month",
                        start_year: "Start year",
                        end_year: "End year",
                    },
                    amount: "Amount",
                    vat: "VAT",
                    no_results_found: "No results found.",
                    discount: "Discount",
                    qte: "QTE",
                    unit: "Unit",
                    sales_type: "Sales type",
                    country_of_origin: "Country of origin",
                    choose_a_country: "Choose a country",
                    tracking_number: "Tracking number",
                    second_hand_good: "Second hand good",
                    purchase_price: "Purchase price",
                    save_article: "Save article",
                    save_btn: "Save",
                    cancel_btn: "Cancel",
                    unit_labels: {
                        piece: "Piece",
                        hour: "Hour",
                        day: "Day",
                        meter: "Meter",
                        kilometer: "Kilometer",
                        gram: "Gram",
                        kilogram: "Kilogram",
                        square_meter: "Square meter",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Discount/Shipping cost",
                discount: "Discount",
                shipping_cost: "Shipping cost",
                vat: "VAT",
                no_results_found: "No results found.",
            },
            payment_section: {
                payment: "Payment",
                payment_method: "Payment method",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateway",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                payment_delay: "Payment delay",
                penalty_rate: "Penalty rate",
                rebate: "Rebate",
                payment_terms: "Payment terms",
                payment_delay_labels: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: 'None',
                }
            },
            option_section: {
                options: "Options",
                comments: "Comments",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
            },
            attachment_section: {
                attachments: "Attachments",
                latest_added_attachments: "Latest Added Attachments",
            }
        }
    },
    credit_notes: {
        nav_title: "Credit notes",
        list: {
            heading: "Credit notes",
            export_popup: {
                error: {
                    invalid_email: "Invalid email",
                    email: "Email address is required",
                },
                export: "Export",
                export_msg: "The report will be sent into your email box within few minutes",
                export_as_pdf: "Export as PDF file",
                export_as_csv: "Export as CSV file",
                heading: "Export credit notes",
                sub_heading: "Select criteria to download the corresponding credit notes",
                export_x_invoice: "Export {{count}} invoices",
                date_filters: {
                    today: "Today",
                    yesterday: "Yesterday",
                    this_week: "This week",
                    last_week: "Last week",
                    this_month: "This month",
                    last_month: "Last month",
                    last_30_days: "Last 30 days",
                    last_90_days: "Last 90 days",
                    last_12_months: "Last 12 months",
                    this_year: "This year",
                    last_year: "Last year",
                },
                reference_date_options: {
                    date_of_issue: "Issue date",
                    date_of_sale: "Sale date",
                    payment_date: "Paid date",
                },
                billing_period: "Billing period",
                more_filters: "More filters",
                less_filters: "Less filters",
                from_input_label: "From",
                to_input_label: "To",
                loading: "Loading...",
                not_found: "No country found",
                country_input_label: "Country",
                no_activity_found: "No activity found",
                activity_input_label: "Activity",
                filter_by: "Filter by",
                recipient: "Recipient",
                email_input_label: "Email",
                cancel_btn: "Cancel",
                export_limit_alert: "You can't export more than {{limit}} elements at a time, please adjust the filters and period",
                pdf_merged_label: "Group invoices in one single file",
            },
            selected: "Selected",
            delete: "Delete",
            all_activity: "All",
            deleted: "Deleted",
            not_found: {
                heading: "Record your credit notes information here",
                sub_heading: "When you issue refunds and void invoices, credit memos are automatically created",
            },
            reference_date: "Reference date:",
            issue_date: "Date",
            refund_date: "Refund date",
            search: "Search",
            delete_popup: {
                heading: "Delete credit notes",
                description: "Do you want to delete these credit notes?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            tabs: {
                all: "All",
                issued: "Issued",
                draft: "Draft"
            },
            columns: {
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date",
                    paid_date: "Paid date",
                    execution_date: "Execution date",
                    refund_date: "Refund date",
                },
                date: "Date",
                reference: "Reference",
                client: "Client",
                subject: "Subject",
                total_exc_taxes: "Total Excl. Taxes",
                total_inc_taxes: "Total Incl. Taxes",
                status: "Status",
                status_labels: {
                    issued: "Issued",
                    draft: "Draft",
                    null: ""
                }
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date",
                    paid_date: "Paid date",
                    execution_date: "Execution date",
                    refund_date: "Refund date",
                },
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
        },
        view: {
            deleted: "Deleted",
            view_invoice: "See invoice",
            issued: "Issued",
            edit: "Edit",
            delete: "Delete",
            void: "Void",
            pay: "Pay",
            finalize_invoice_first: "Finalize the invoice first",
            finalize: "Finalize",
            finalize_popup: {
                heading: "Finalize invoice?",
                description: "The issued invoice won't be editable any longer, are you sure?",
                cancel_btn: "Cancel",
                finalize_btn: "Finalize",
            },
            status_tag: {
                paid: "Paid",
                cancelled: "Cancelled",
                overdue: "Overdue",
                draft: "Draft",
                not_paid: "Not paid",
                null: ""
            },
            delete_popup: {
                heading: "Delete Invoice",
                void_heading: "Void the invoice?",
                description: "Do you want to delete this invoice?",
                void_description: "A credit note will be created to void this invoice",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
                create_credit_note_btn: "Issue a credit note",
            },
            summary: {
                client: "Client",
                total_excl_vat: "Total excl. VAT",
                total_incl_vat: "Total incl. VAT",
                date: "Date",
                overview: "Overview",
                delay_expiration: "Delay before expiration",
                days: "Days",
                urssaf_taxes: "URSSAF taxes",
                status_of_payments: "Payments",
                sales_overview: "Sales overview",
                warning_overdue_days: "Warning! Payment is now overdue by {{days}} days!",
                warning_overdue_day: "Warning! Payment is now overdue by {{day}} day!",
                delay_before_due_date: "Deadline",
                overdue: "Overdue",
            },
            toolbar: {
                view_detail_options: {
                    events: "Events",
                    payments: "Refunds",
                    credit_notes: "Credit notes",
                    attachments: "Attachments",
                },
                download: "Download",
                print: "Print",
                send: "Send",
                hide_notes: "Hide notes",
                notes: "Notes",
                show_more: "Show more",
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                }
            },
            more_details_panel: {
                events: {
                    history: "History",
                    timeline_of_events: "Timeline of events",
                },
                payments: {
                    delete_payment_success: "The payment has been deleted",
                    certificate_name: "payments_certificate_{{name}}.pdf",
                    downloaded: "Downloaded",
                    download_statements: "Download payments statement",
                    payments: "Payments",
                    payment_list: "List of payments attached to this invoice",
                    refunds: "Refunds",
                    list_refunds_to_credit_note: "List of refunds attached to this credit note",
                    payment_methods: {
                        bank_transfer: "Bank transfer",
                        cash: "Cash",
                        check: "Check",
                        cesu: "Cesu",
                        direct_debit: "Direct debit",
                        credit_card: "Credit card",
                        other: "Other",
                        paypal: "PayPal",
                        gift_card: "Gift card",
                        null: ""
                    },
                    delete_popup: {
                        heading: "Delete Payment?",
                        description: "This operation cannot be reversed!",
                        cancel_btn: "Cancel",
                        delete_btn: "Delete",
                    }
                },
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            payment_modal: {
                transaction_link_desc: "This income is linked to a bank transaction and some elements cannot be modified",
                error: {
                    amount: "Amount is required",
                    date: "Date is required",
                    method: "Method is required",
                    invoice: "Invoice is required",
                    amount_min_max: "Allowed between {{min}} to {{max}}",
                },
                create_payment_success: "The payment has been created",
                update_payment_success: "The payment has been updated",
                invoice_to_xxx: "Invoice to {{name}}",
                payment: "Payment",
                loading: "Loading...",
                no_invoice: "No invoice found",
                invoice_to_paid: "Invoice to be paid",
                date: "Date",
                amount: "Amount",
                label: "Label",
                payment_mode: "Payment mode",
                notes: "Notes",
                note_by_cheque: "Note on cheque, client, ...",
                cancel_btn: "Cancel",
                view_invoice_btn: "View invoice",
                view_credit_note_btn: "View credit note",
                save_btn: "Save",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                }
            },
            edit_popup: {
                error: {
                    activity: "Activity is required",
                    title: 'Title is required',
                    quantity: 'Required',
                    country: "Country is required",
                    price: "Price is required",
                    payment_gateway: "Payment gateway required",
                },
                modify_invoice: "Modify invoice",
                updated: "Updated",
                sales_type: "Sales type",
                country_of_origin: "Country of origin",
                choose_a_country: "Choose a country",
                tracking_number: "Tracking number",
                purchase_price: "Purchase price",
                activity: "Activity",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
                cancel_btn: "Cancel",
                save_btn: "Save",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateway",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
            },
        },
        edit: {
            issued: "Issued",
            draft: "Draft",
            error: {
                date: "Date is required",
                invalid_date: "Date is invalid",
                language: "Language is required",
                currency: "Currency is required",
                activity: "Activity is required",
                template: "Template is required",
                last_name: "Last name is required",
                country: "Country is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                client: "Client is required",
                zip: "Zip is required",
                title: 'Title is required',
                quantity: 'Required',
                price: "Price is required",
                price_min: "Minimum price allowed {{amount}}",
                tree: 'At least 1 article is required',
                tax_rate: "Required",
            },
            restore_version: "You have unsaved elements",
            draft_actions: {
                apply: "Restore",
                discard: "Ignore",
            },
            created: "Credit note issued",
            updated: "Credit updated",
            saved: "Saved",
            cancel_btn: "Cancel",
            save_btn: "Save",
            confirm_rule_popup: {
                heading: "Update of VAT",
                description: "Rules of taxes have changes, do you want to apply them",
                yes_btn: "Yes",
                no_btn: "No",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "Taxes incl.",
                    vat_excl: "Taxes excl.",
                },
                vat_labels: {
                    no: "No",
                    yes: "Yes",
                },
                vat_exempt: "VAT exempt",
                price_format: "Price Format",
                exemption_text: "Exemption Text",
                exemption_placeholder: "Statement...",
            },
            filter_section: {
                language_input_label: "Language",
                currency_input_label: "Currency",
                activity_input_label: "Activity",
                select_template_input_label: "Template",
                subject_input_label: "Subject",
                invoice_title: "Invoice title...",
                expiration_date_input_label: "Expiration date",
                order_date_input_label: "Order date",
            },
            pdf_section: {
                loading_pdf: "Loading PDF",
                of: "of",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Choose a client",
                no_client_found: "No Client Found",
                use_another_shipping: "Use another shipping address",
                another_shipping: {
                    company_input_label: "Company",
                    vat_number_input_label: "VAT number",
                    registration_number_input_label: "Registration number",
                    last_name_input_label: "Last name",
                    first_name_input_label: "First name",
                    address_input_label: "Address",
                    address_supplementary_input_label: "Address supplementary",
                    zip_input_label: "Zip",
                    city_input_label: "City",
                    country_input_label: "Country",
                    client_type_labels: {
                        individual: "Individual",
                        business: "Company",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "No Article Found",
                loading: "Loading...",
                choose_article: "Choose article",
                search_articles: "Search article...",
                edit_line: "Edit line",
                add_new_line: "Add a new line",
                edit_popup: {
                    ref: "REF",
                    title: "Title",
                    description: "Description",
                    insert_tag: "Insert a tag:",
                    tags: {
                        start_date: "Start date",
                        end_date: "End date",
                        start_month: "Start month",
                        end_month: "End month",
                        start_year: "Start year",
                        end_year: "End year",
                    },
                    amount: "Amount",
                    vat: "VAT",
                    no_results_found: "No results found.",
                    discount: "Discount",
                    qte: "QTE",
                    unit: "Unit",
                    sales_type: "Sales type",
                    country_of_origin: "Country of origin",
                    choose_a_country: "Choose a country",
                    tracking_number: "Tracking number",
                    second_hand_good: "Second hand good",
                    purchase_price: "Purchase price",
                    save_article: "Save article",
                    save_btn: "Save",
                    cancel_btn: "Cancel",
                    unit_labels: {
                        piece: "Piece",
                        hour: "Hour",
                        day: "Day",
                        meter: "Meter",
                        kilometer: "Kilometer",
                        gram: "Gram",
                        kilogram: "Kilogram",
                        square_meter: "Square meter",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Discount/Shipping cost",
                discount: "Discount",
                shipping_cost: "Shipping cost",
                vat: "VAT",
                no_results_found: "No results found.",
            },
            option_section: {
                options: "Options",
                comments: "Comments",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
            },
        }
    },
    payments: {
        nav_title: "Payments",
        list: {
            list_of_payments: "List of payments",
            success: "Success",
            import: "Import",
            external_payment: "External Payment",
            import_popup: {
                success: "Success",
                heading: "Import payments",
                sub_heading: "Select your file containing the list of payments to import",
                validate_btn: "Validate",
                import_alert: "To learn more about the steps to follow, please read this article:",
                cancel_btn: "Cancel",
                drop_csv_here: "Drop your CSV file here",
            },
            export_popup: {
                error: {
                    invalid_email: "Invalid email",
                    email: "Email address is required",
                },
                export: "Export",
                export_msg: "The report will be sent into your email box within few minutes",
                export_as_pdf: "Export as PDF file",
                export_as_csv: "Export as CSV file",
                heading: "Export payments",
                sub_heading: "Select criteria to download the corresponding payments",
                export_x_payments: "Export {{count}} payments",
                date_filters: {
                    today: "Today",
                    yesterday: "Yesterday",
                    this_week: "This week",
                    last_week: "Last week",
                    this_month: "This month",
                    last_month: "Last month",
                    last_30_days: "Last 30 days",
                    last_90_days: "Last 90 days",
                    last_12_months: "Last 12 months",
                    this_year: "This year",
                    last_year: "Last year",
                },
                billing_period: "Billing period",
                more_filters: "More filters",
                less_filters: "Less filters",
                from_input_label: "From",
                to_input_label: "To",
                loading: "Loading...",
                client_input_label: "Client",
                not_found: "No country found",
                country_input_label: "Country",
                no_activity_found: "No activity found",
                activity_input_label: "Activity",
                filter_by: "Filter by",
                recipient: "Recipient",
                email_input_label: "Email",
                cancel_btn: "Cancel"
            },
            all_activity: "All",
            deleted: "Deleted",
            delete: "Delete",
            selected: "Selected",
            not_found: {
                heading: "View the revenue from your activity here",
                sub_heading: "When collecting invoices, receipts are automatically added",
            },
            tabs: {
                all: "All",
                payments: "Payments",
                refunds: "Refunds",
            },
            columns: {
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                date: "Date",
                reference: "Reference",
                client: "Client",
                label: "Label",
                total_exc_taxes: "Total Excl. Taxes",
                total_inc_taxes: "Total Incl. Taxes",
                payment_method: "Payment method",
            },
            search_invoice_number: "Search expense...",
            delete_popup: {
                heading: "Delete Payments?",
                description: "This operation cannot be reversed!",
                cancel_btn: "Cancel",
                delete_btn: "Delete"
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
            payment_modal: {
                transaction_link_desc: "This income is linked to a bank transaction and some elements cannot be modified",
                error: {
                    amount: "Amount is required",
                    date: "Date is required",
                    method: "Method is required",
                    invoice: "Invoice is required",
                    amount_min_max: "Allowed between {{min}} to {{max}}",
                },
                create_payment_success: "The payment has been created",
                update_payment_success: "The payment has been updated",
                invoice_to_xxx: "Invoice to {{name}}",
                payment: "Payment",
                loading: "Loading...",
                no_invoice: "No invoice found",
                invoice_to_paid: "Invoice to be paid",
                date: "Date",
                amount: "Amount",
                label: "Label",
                payment_mode: "Payment mode",
                notes: "Notes",
                note_by_cheque: "Note on cheque, client, ...",
                cancel_btn: "Cancel",
                view_invoice_btn: "View invoice",
                view_credit_note_btn: "View credit note",
                save_btn: "Save",
                can_not_edit_voided: "Cannot edit voided invoice payment",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                }
            },
        },
        edit: {
            not_found: "No payment found",
            created: "Payment created",
            updated: "Payment updated",
            deleted: "Deleted",
            external_payment: "External Payment",
            cancel_btn: "Cancel",
            delete_btn: "Delete",
            update_btn: "Update",
            create_btn: "Create",
            info_alert: "This payment is linked to a bank transaction and some elements cannot be modified",
            delete_popup: {
                heading: "Delete Payment?",
                description: "This operation cannot be reversed!",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            rule_popup: {
                heading: "Update of VAT",
                description: "Rules of taxes have changes, do you want to apply them",
                no_btn: "No",
                yes_btn: "Yes",
            },
            error: {
                date: "Date is required",
                activity: "Activity is required",
                client: "Client is required",
                method: "Payment method required",
                currency: "Currency is required",
                label: "Label is required",
                amount: "Amount is required",
                tax_rate: "Tax rate is required",
                type: "Type is required",
                origin_country_code: "Country is required",
                total_must_be_xxx: "The total of the payment must be {{total}}",
            },
            date_input_label: "Date",
            activity_input_label: "Activity",
            client_input_label: "Client",
            no_client_found: "No client found",
            currency_input_label: "Currency",
            payment_mode_input_label: "Payment mode",
            payment_methods: {
                bank_transfer: "Bank transfer",
                cash: "Cash",
                check: "Check",
                cesu: "Cesu",
                direct_debit: "Direct debit",
                credit_card: "Credit card",
                other: "Other",
                paypal: "PayPal",
                gift_card: "Gift card",
                null: ""
            },
            label_input_label: "Label",
            reference_input_label: "Reference",
            vat_exemption: "VAT exemption",
            title_input_label: "Title",
            amount_input_label: "Amount",
            vat_input_label: "VAT",
            no_results_found: "No results found.",
            sales_type_input_label: "Sales type",
            choose_a_country: "Choose a country",
            country_origin_input_label: "Origin country",
            add_another_line_btn: "Add another line",
            total_amount: "Total: ",
            notes_input_label: "Notes",
            documents: "Documents",
            vat_labels: {
                no: "No",
                yes: "Yes",
            },
        }
    },
    quotes: {
        nav_title: "Quotes",
        list: {
            list_quotes: "List of quotes",
            quotes: "Quotes",
            new_quote_btn: "New Quote",
            deleted: "Quote deleted",
            all_activity: "All",
            selected: "Selected",
            delete: "Delete",
            not_found: {
                heading: "Record your quotes information here",
                sub_heading: "You can directly invoice quotes accepted by your customers",
            },
            summary: {
                all: "All",
                quotes: "Quotes",
                pending: "Pending",
                accepted: "Accepted",
                declined: "Declined",
                closed: "Closed",
                draft: "Draft",
            },
            tabs: {
                all: "All",
                pending: "Pending",
                accepted: "Accepted",
                declined: "Declined",
                closed: "Closed",
                draft: "Draft",
            },
            search_client_or_invoices: "Search client or invoice number",
            delete_popup: {
                heading: "Delete Quote",
                description: "Do you want to delete this quote?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            columns: {
                date: "Date",
                reference: "Reference",
                client: "Client",
                subject: "Subject",
                total_exc_taxes: "Total Excl. Taxes",
                total_inc_taxes: "Total Incl. Taxes",
                status: "Status",
                status_tag: {
                    pending: "Pending",
                    accepted: "Accepted",
                    declined: "Declined",
                    closed: "Closed",
                    draft: "Draft",
                    null: ""
                },
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
        },
        view: {
            view_quote: "View quote",
            deleted: "Quote deleted",
            set_quote: "Set the quote",
            finalize_success: "Quote finalized",
            accept_confirm: "Quote accepted",
            decline_confirm: "Quote declined",
            accepted: "Accepted",
            declined: "Declined",
            duplicate: "Duplicate",
            status_tag: {
                pending: "Pending",
                accepted: "Accepted",
                declined: "Declined",
                closed: "Closed",
                draft: "Draft",
                null: ""
            },
            note: {
                note_added: "Note Added",
                error: {
                    content_require: "Content require",
                },
            },
            down_payment: "Down payment",
            down_payment_description: "Enter the amount excluding tax as well as the subject and comments that will appear on the down payment",
            final_invoice: "Final invoice",
            enter_subject_comments: "Enter the subject and comments that will appear on the final invoice",
            duplicated: "Duplicated",
            edit: "Edit",
            more_actions: "More actions",
            created_delivery_form: "Delivery form created",
            edit_menu: {
                modify_quote: "Modify quote",
                duplicate_quote: "Duplicate quote",
                create_delivery_form: "Create a delivery form",
            },
            delete: "Delete",
            finalize: "Finalize",
            issue_down_payment: "Issue the down payment",
            issue_final_invoice: "Issue the final invoice",
            delete_popup: {
                heading: "Delete Quote",
                description: "Do you want to delete this quote?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            finalize_popup: {
                heading: "Finalize quote?",
                description: "The issued quote won't be editable any longer, are you sure?",
                cancel_btn: "Cancel",
                finalize_btn: "Finalize",
            },
            status_popup: {
                heading: "Change status?",
                description: "The quote won't be editable after accepting or declining it!",
                cancel_btn: "Cancel",
                confirm_btn: "Confirm",
            },
            modify_invoice: "Modify invoice",
            status_btn: {
                issue_invoice: "Issue invoice",
                deposit_invoice: "Down payment",
                final_invoice: "Final invoice",
            },
            summary: {
                client: "Client",
                total_excl_vat: "Total excl. VAT",
                total_incl_vat: "Total incl. VAT",
                date: "Date",
                overview: "Overview",
                delay_expiration: "Delay before expiration",
                days: "Days",
                urssaf_taxes: "URSSAF taxes",
            },
            toolbar: {
                view_detail_options: {
                    events: "Events",
                    payments: "Payments",
                    credit_notes: "Credit notes",
                    attachments: "Attachments",
                },
                download: "Download",
                print: "Print",
                send: "Send",
                hide_notes: "Hide notes",
                notes: "Notes",
                show_more: "Show more",
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                }
            },
            more_details_panel: {
                events: {
                    history: "History",
                    timeline_of_events: "Timeline of events",
                },
                attachments: {
                    attachments: "Attachments",
                    select_files: "Select files",
                }
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            down_pay_modal: {
                error: {
                    amount_min: "Minimum amount allowed {{amount}}",
                    amount_max: "Maximum amount allowed {{amount}}",
                    amount: "Amount is required",
                },
                summary: "Summary",
                total_down_payments: "Total amount of down payments:",
                remainder_to_invoiced: "Reminder to be invoiced:",
                amount_tax_symbol: {
                    false: "Amount excluding tax (in {{symbol}})",
                    true: "Amount including tax (in {{symbol}})",
                },
                including_taxes: "including taxes",
                excluding_taxes: "excluding taxes",
                subject: "Subject",
                comments: "Comments",
                create_down_payment_draft: "Create a draft document",
                cancel_btn: "Cancel",
                price_format: "Price Format",
                price_labels: {
                    vat_incl: "VAT incl.",
                    vat_excl: "VAT excl.",
                },
            },
            edit_popup: {
                error: {
                    activity: "Activity is required",
                    title: 'Title is required',
                    quantity: 'Required',
                    country: "Country is required",
                    price: "Price is required",
                    payment_gateway: "Payment gateway required",
                },
                updated: "Updated",
                sales_type: "Sales type",
                country_of_origin: "Country of origin",
                choose_a_country: "Choose a country",
                tracking_number: "Tracking number",
                purchase_price: "Purchase price",
                activity: "Activity",
                cancel_btn: "Cancel",
                save_btn: "Save",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateway",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
            },
        },
        edit: {
            status_tag: {
                pending: "Pending",
                accepted: "Accepted",
                declined: "Declined",
                closed: "Closed",
                draft: "Draft",
                null: ""
            },
            new_quote: "New Quote",
            edit_quote: "Edit quote",
            error: {
                language: "Language is required",
                currency: "Currency is required",
                activity: "Activity is required",
                template: "Template is required",
                last_name: "Last name is required",
                country: "Country is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                client: "Client is required",
                zip: "Zip is required",
                title: 'Title is required',
                quantity: 'Required',
                price: "Price is required",
                price_min: "Minimum price allowed {{amount}}",
                tree: 'At least 1 article is required',
                expiration_date: "Expiration date is required",
                min_tomorrow: "Min tomorrow allowed",
                payment_gateway: "Payment gateway required",
                tax_rate: "Required",
            },
            restore_version: "You have unsaved elements",
            draft_actions: {
                apply: "Restore",
                discard: "Ignore",
            },
            created: "Quote created",
            updated: "Quote updated",
            saved: "Saved",
            cancel_btn: "Cancel",
            save_btn: "Save",
            confirm_rule_popup: {
                heading: "Update of VAT",
                description: "Rules of taxes have changes, do you want to apply them",
                yes_btn: "Yes",
                no_btn: "No",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "Taxes incl.",
                    vat_excl: "Taxes excl.",
                },
                vat_labels: {
                    no: "No",
                    yes: "Yes",
                },
                vat_exempt: "VAT exempt",
                price_format: "Price Format",
                exemption_text: "Exemption Text",
                exemption_placeholder: "Statement...",
            },
            filter_section: {
                language_input_label: "Language",
                currency_input_label: "Currency",
                activity_input_label: "Activity",
                select_template_input_label: "Template",
                subject_input_label: "Subject",
                invoice_title: "Invoice title...",
                expiration_date_input_label: "Expiration date",
            },
            pdf_section: {
                loading_pdf: "Loading PDF",
                of: "of",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Choose a client",
                no_client_found: "No Client Found",
                use_another_shipping: "Use another shipping address",
                another_shipping: {
                    company_input_label: "Company",
                    vat_number_input_label: "VAT number",
                    registration_number_input_label: "Registration number",
                    last_name_input_label: "Last name",
                    first_name_input_label: "First name",
                    address_input_label: "Address",
                    address_supplementary_input_label: "Address supplementary",
                    zip_input_label: "Zip",
                    city_input_label: "City",
                    country_input_label: "Country",
                    client_type_labels: {
                        individual: "Individual",
                        business: "Company",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "No Article Found",
                loading: "Loading...",
                choose_article: "Choose article",
                search_articles: "Search article...",
                edit_line: "Edit line",
                add_new_line: "Add a new line",
                edit_popup: {
                    ref: "REF",
                    title: "Title",
                    description: "Description",
                    insert_tag: "Insert a tag:",
                    tags: {
                        start_date: "Start date",
                        end_date: "End date",
                        start_month: "Start month",
                        end_month: "End month",
                        start_year: "Start year",
                        end_year: "End year",
                    },
                    amount: "Amount",
                    vat: "VAT",
                    no_results_found: "No results found.",
                    discount: "Discount",
                    qte: "QTE",
                    unit: "Unit",
                    sales_type: "Sales type",
                    country_of_origin: "Country of origin",
                    choose_a_country: "Choose a country",
                    tracking_number: "Tracking number",
                    second_hand_good: "Second hand good",
                    purchase_price: "Purchase price",
                    save_article: "Save article",
                    save_btn: "Save",
                    cancel_btn: "Cancel",
                    unit_labels: {
                        piece: "Piece",
                        hour: "Hour",
                        day: "Day",
                        meter: "Meter",
                        kilometer: "Kilometer",
                        gram: "Gram",
                        kilogram: "Kilogram",
                        square_meter: "Square meter",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Discount/Shipping cost",
                discount: "Discount",
                shipping_cost: "Shipping cost",
                vat: "VAT",
                no_results_found: "No results found.",
            },
            payment_section: {
                payment: "Payment",
                payment_method: "Payment method",
                accept_online_payment: "Activate online payment",
                select_payment_gateways: "Select payment gateway",
                same_type_payment_already_selected: "Already same type selected",
                connect_payment_gateway: "Connect a service to accept online payments",
                connect_btn: "Connect",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                payment_delay: "Payment delay",
                penalty_rate: "Penalty rate",
                rebate: "Rebate",
                payment_terms: "Payment terms",
                payment_delay_labels: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: 'None',
                }
            },
            option_section: {
                options: "Options",
                comments: "Comments",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
            },
            attachment_section: {
                attachments: "Attachments",
                latest_added_attachments: "Latest Added Attachments",
            }
        }
    },
    quote: {
        history: {
            email: "Quote sent to {{email}}",
            emailSent: "Quote sent to {{email}}",
            issued: "Quote issued",
            billed: "Quote billed",
            downPayment: "Down payment of {{currency}}{{total}} issued",
            emailRead: "Quote read",
            modified: "Quote modified",
            accepted: "Accepted",
            declined: "Declined"
        },
    },
    delivery_notes: {
        nav_title: "Delivery notes",
        list: {
            delivery_notes: "Delivery notes",
            list_delivery_notes: "List of delivery notes",
            new_delivery_note: "New delivery note",
            all_activity: "All",
            deleted: "Deleted",
            delete: "Delete",
            selected: "Selected",
            not_found: {
                heading: "Record your delivery notes information here",
                sub_heading: "These documents make it possible to prove the good delivery of the goods",
            },
            search: "Search",
            delete_popup: {
                heading: "Delete delivery notes",
                description: "Do you want to delete delivery notes?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
            tabs: {
                all: "All",
                issued: "Issued",
                draft: "Draft",
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
            },
            columns: {
                date: "Date",
                reference: "Reference",
                client: "Client",
                subject: "Subject",
                status: "Status",
                status_tag: {
                    issued: "Issued",
                    draft: "Draft",
                    null: ""
                },
            },
        },
        view: {
            deleted: "Deleted",
            issued: "Delivery note issued",
            delivery_note_view: "View delivery note",
            status_tag: {
                issued: "Issued",
                draft: "Draft",
                null: ""
            },
            edit: "Edit",
            delete: "Delete",
            finalize: "Finalize",
            summary: {
                client: "Clients",
                date: "Date",
            },
            toolbar: {
                view_detail_options: {
                    events: "Events",
                    payments: "Payments",
                    credit_notes: "Credit notes",
                    attachments: "Attachments",
                },
                download: "Download",
                print: "Print",
                send: "Send",
                hide_notes: "Hide notes",
                notes: "Notes",
                show_more: "Show more",
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                }
            },
            more_details_panel: {
                events: {
                    history: "History",
                    timeline_of_events: "Timeline of events",
                },
                attachments: {
                    attachments: "Attachments",
                    select_files: "Select files",
                }
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            finalize_popup: {
                heading: "Finalize delivery note?",
                description: "The issued delivery note won't be editable any longer, are you sure?",
                cancel_btn: "Cancel",
                finalize_btn: "Finalize",
            },
            delete_popup: {
                heading: "Delete delivery note",
                description: "Do you want to delete this delivery note?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
        },
        edit: {
            new_delivery_note: "New delivery note",
            status_tags: {
                issued: "Issued",
                draft: "Draft",
                null: ""
            },
            edit_not_allowed: "Edit not allowed!",
            error: {
                language: "Language is required",
                currency: "Currency is required",
                activity: "Activity is required",
                template: "Template is required",
                last_name: "Last name is required",
                country: "Country is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                client: "Client is required",
                zip: "Zip is required",
                title: 'Title is required',
                quantity: 'Required',
                price: "Price is required",
                price_min: "Minimum price allowed {{amount}}",
                tree: 'At least 1 article is required',
            },
            restore_version: "You have unsaved elements",
            created: "Delivery note created",
            updated: "Delivery note updated",
            edit_delivery_note: "Edit delivery note",
            cancel_btn: "Cancel",
            save_btn: "Save",
            draft_actions: {
                apply: "Restore",
                discard: "Ignore",
            },
            filter_section: {
                language_input_label: "Language",
                currency_input_label: "Currency",
                activity_input_label: "Activity",
                select_template_input_label: "Template",
                subject_input_label: "Subject",
                invoice_title: "Invoice title...",
                expiration_date_input_label: "Expiration date",
            },
            pdf_section: {
                loading_pdf: "Loading PDF",
                of: "of",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Choose a client",
                no_client_found: "No Client Found",
                use_another_shipping: "Use another shipping address",
                another_shipping: {
                    company_input_label: "Company",
                    vat_number_input_label: "VAT number",
                    registration_number_input_label: "Registration number",
                    last_name_input_label: "Last name",
                    first_name_input_label: "First name",
                    address_input_label: "Address",
                    address_supplementary_input_label: "Address supplementary",
                    zip_input_label: "Zip",
                    city_input_label: "City",
                    country_input_label: "Country",
                    client_type_labels: {
                        individual: "Individual",
                        business: "Company",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "No Article Found",
                loading: "Loading...",
                choose_article: "Choose article",
                search_articles: "Search article...",
                edit_line: "Edit line",
                add_new_line: "Add a new line",
                edit_popup: {
                    ref: "REF",
                    title: "Title",
                    description: "Description",
                    insert_tag: "Insert a tag:",
                    tags: {
                        start_date: "Start date",
                        end_date: "End date",
                        start_month: "Start month",
                        end_month: "End month",
                        start_year: "Start year",
                        end_year: "End year",
                    },
                    amount: "Amount",
                    vat: "VAT",
                    no_results_found: "No results found.",
                    discount: "Discount",
                    qte: "QTE",
                    unit: "Unit",
                    sales_type: "Sales type",
                    country_of_origin: "Country of origin",
                    choose_a_country: "Choose a country",
                    tracking_number: "Tracking number",
                    second_hand_good: "Second hand good",
                    purchase_price: "Purchase price",
                    save_article: "Save article",
                    save_btn: "Save",
                    cancel_btn: "Cancel",
                    unit_labels: {
                        piece: "Piece",
                        hour: "Hour",
                        day: "Day",
                        meter: "Meter",
                        kilometer: "Kilometer",
                        gram: "Gram",
                        kilogram: "Kilogram",
                        square_meter: "Square meter",
                    }
                }
            },
            option_section: {
                options: "Options",
                comments: "Comments",
                tracking_numbers: "Tracking numbers",
                shipping_companies: "Shipping companies",
            },
            attachment_section: {
                attachments: "Attachments",
                latest_added_attachments: "Latest Added Attachments",
            }
        }
    },
    deliveryForm: {
        history: {
            email: "Delivery note sent to {{email}}",
            emailSent: "Delivery note sent to {{email}}",
            paymentAdded: "Payment of {{currency}}{{total}} added",
            paymentRemoved: "Payment of {{currency}}{{total}} deleted",
            issued: "Delivery note issued",
            backIssued: "Delivery note not paid",
            cancelled: "Delivery note cancelled",
            asset: "A credit note has been issued",
            created: "Delivery note issued",
            paid: "Delivery note paid",
            issuedAsset: "Credit note issued",
            paymentGift: "Gift card of {{currency}}{{total}} used",
            emailRead: "Delivery note read"
        },
    },
    expenses: {
        nav_title: "Expenses",
        list: {
            delete: "Delete",
            export_popup: {
                error: {
                    invalid_email: "Invalid email",
                    email: "Email address is required",
                },
                export: "Export",
                export_msg: "The report will be sent into your email box within few minutes",
                export_as_pdf: "Export as PDF file",
                export_as_csv: "Export as CSV file",
                heading: "Export expenses",
                sub_heading: "Select a period to download an extract of your purchases",
                export_x_expenses: "Export {{count}} expenses",
                date_filters: {
                    today: "Today",
                    yesterday: "Yesterday",
                    this_week: "This week",
                    last_week: "Last week",
                    this_month: "This month",
                    last_month: "Last month",
                    last_30_days: "Last 30 days",
                    last_90_days: "Last 90 days",
                    last_12_months: "Last 12 months",
                    this_year: "This year",
                    last_year: "Last year",
                },
                reference_date_options: {
                    date_of_issue: "Issue date",
                    date_of_sale: "Sale date",
                    payment_date: "Paid date",
                },
                billing_period: "Billing period",
                more_filters: "More filters",
                less_filters: "Less filters",
                from_input_label: "From",
                to_input_label: "To",
                loading: "Loading...",
                not_found: "No Supplier found",
                supplier_input_label: "Supplier",
                no_activity_found: "No activity found",
                activity_input_label: "Activity",
                filter_by: "Filter by",
                attachments: "Attachments",
                include_attachments: "Include Attachments",
                recipient: "Recipient",
                email_input_label: "Email",
                cancel_btn: "Cancel"
            },
            import_popup: {
                success: "Success",
                heading: "Import expenses",
                sub_heading: "Select your file containing the list of expenses to import",
                validate_btn: "Validate",
                import_alert: "To learn more about the steps to follow, please read this article:",
                cancel_btn: "Cancel",
                drop_csv_here: "Drop your CSV file here",
            },
            nav_title: "Expenses List",
            heading: "Expenses",
            import_btn: "Import",
            new_expense_btn: "New expense",
            all: "All",
            deleted: "Deleted",
            not_found: {
                heading: "Record your expense information here",
                sub_heading: "Expenses allow you to track the profitability of your business",
            },
            search_invoice_number: "Search expense...",
            selected: "Selected",
            delete_popup: {
                heading: "Delete Expenses",
                description: "Do you want to delete expenses?",
                cancel_btn: "Cancel",
                delete_btn: "Delete"
            },
            tabs: {
                all: "All",
                with_attachment: "Expenses with attachments",
                without_attachment: "Expenses without attachments",
            },
            toolbar: {
                activity: "Activity",
                start_date: "Start date",
                end_date: "End date",
                date: "Date",
                clear: "Clear",
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
            columns: {
                date: "Date",
                supplier: "Supplier",
                label: "Label",
                total_exc_taxes: "Total Excl. Taxes",
                total_inc_taxes: "Total Incl. Taxes",
                payment_method: "Payment method",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                attachments: "Attachments",
                yes: "Yes",
                no: "No"
            },
        },
        edit: {
            error: {
                not_found: "No expense found",
                date: "Date is required",
                activity: "Activity is required",
                currency: "Currency is required",
                amount: "Amount is required",
                tax_rate: "Required",
                supplier: "Supplier is required",
                method: "Payment method required",
                type_required: "Type is required"
            },
            created: "Expense created",
            updated: "Expense updated",
            deleted: "Deleted",
            heading: "Expense",
            cancel_btn: "Cancel",
            delete_btn: "Delete",
            update_btn: "Update",
            create_btn: "Create",
            info_alert: "This expense is linked to a bank transaction and some elements cannot be modified",
            delete_popup: {
                heading: "Delete Expenses",
                description: "Do you want to delete expenses?",
                cancel_btn: "Cancel",
                delete_btn: "Delete"
            },
            date_input_label: "Date",
            activity_input_label: "Activity",
            supplier_input_label: "Supplier",
            not_found_input_label: "No Supplier found",
            payment_mode_input_label: "Payment mode",
            currency_input_label: "Currency",
            amount_input_label: "Amount",
            vat_rate_input_label: "VAT Rate",
            amount_excl_vat_input_label: "Amount excl. VAT",
            no_results_found: "No results found.",
            toggle_vat_labels: {
                vat_rate: "VAT Rate",
                amount_excl_vat: "Amount excl. VAT",
            },
            type_input_label: "Type",
            label_input_label: "Label",
            reference_input_label: "Reference",
            payment_methods: {
                bank_transfer: "Bank transfer",
                cash: "Cash",
                check: "Check",
                cesu: "Cesu",
                direct_debit: "Direct debit",
                credit_card: "Credit card",
                other: "Other",
                paypal: "PayPal",
                gift_card: "Gift card",
                null: ""
            },
            notes_input_label: "Notes",
            documents: "Documents",
            select_files: "Select files",
        }
    },
    recurring_expenses: {
        nav_title: "Recurring",
        list: {
            heading: "Recurring expenses",
            new_expense_btn: "New recurring expense",
            deleted: "Deleted",
            delete_fail: "Unable to delete",
            disabled: "Recurring expense disabled",
            enabled: "Recurring expense enabled",
            not_found: {
                heading: "Recurring expenses information here",
                sub_heading: "The expenses will be automatically created with the information indicated, on the scheduled date",
            },
            supplier: "Supplier",
            frequency_title: "Frequency",
            duration_title: "Duration",
            next_exp: "Next expense",
            amount: "Amount",
            status: "Status",
            edit_btn: "Edit",
            delete_popup: {
                heading: "Warning",
                description: "Are you sure you want to delete the recurring expense?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            edit_menu: {
                disable: "Disable",
                enable: "Enable",
                modify: "Modify",
                delete: "Delete",
            },
            frequency: {
                weekly: "Weekly",
                monthly: "Monthly",
                quarterly: "Quarterly",
                yearly: "Yearly",
                custom: "Custom",
            },
            duration: {
                not_limited: "Not limited",
                stop_after_date: "Stop after a date",
                until: "Until"
            },
            status_labels: {
                enabled: "Active",
                disabled: "Inactive"
            },
        },
        edit: {
            error: {
                not_found: "No recurring expense found",
                invalid_date: "Date is invalid",
                date: "Date is required",
                activity: "Activity is required",
                type: "Type is required",
                currency: "Currency is required",
                amount: "Amount is required",
                supplier: "Supplier is required",
                method: "Payment method required",
                min_start_date: "End date must be after start date",
                occurrence_type: "Type is required",
                occurrence_value: "Value is required",
            },
            created: "Recurring expense created",
            updated: "Recurring expense updated",
            expenses: "Expenses",
            cancel_btn: "Cancel",
            update_btn: "Update",
            create_btn: "Create",
            expense: "Expense",
            frequency: {
                weekly: "Weekly",
                monthly: "Monthly",
                quarterly: "Quarterly",
                yearly: "Yearly",
                custom: "Custom",
            },
            duration: {
                not_limited: "Not limited",
                stop_after_date: "Stop after a date",
                until: "Until"
            },
            dwmy: {
                day: "Days",
                week: "Week",
                month: "Month",
                year: "Year",
            },
            settings: "Settings",
            date_first_invoice: "Date of 1st invoice",
            frequency_title: "Frequency",
            duration_title: "Duration",
            send_invoice: "Send invoice",
            date_input_label: "Date",
            activity_input_label: "Activity",
            supplier_input_label: "Supplier",
            not_found_input_label: "No Supplier found",
            payment_mode_input_label: "Payment mode",
            currency_input_label: "Currency",
            amount_input_label: "Amount",
            vat_rate_input_label: "VAT Rate",
            amount_excl_vat_input_label: "Amount excl. VAT",
            no_results_found: "No results found.",
            toggle_vat_labels: {
                vat_rate: "VAT Rate",
                amount_excl_vat: "Amount excl. VAT",
            },
            type_input_label: "Type",
            label_input_label: "Label",
            reference_input_label: "Reference",
            payment_methods: {
                bank_transfer: "Bank transfer",
                cash: "Cash",
                check: "Check",
                cesu: "Cesu",
                direct_debit: "Direct debit",
                credit_card: "Credit card",
                other: "Other",
                paypal: "PayPal",
                gift_card: "Gift card",
                null: ""
            },
            notes_input_label: "Notes",
            documents: "Documents",
            select_files: "Select files",
        }
    },
    expense_types: {
        nav_title: "Types",
        title: "Expense type",
        add_new_type_btn: "Add a new type",
        expense_types: "Types",
        edit_type: "Edit expenses type",
        create_type: "Create expenses type",
        type_name: "Type name",
        created: "Type created",
        updated: "Type updated",
        cancel_btn: "Cancel",
        save_btn: "Save",
        error: {
            same_name: "Already same name type exist",
            name: "Name is required",
        },
        delete_popup: {
            heading: "Warning",
            description: "Are you sure you want to delete the Expenses type?",
            cancel_btn: "Cancel",
            delete_btn: "Delete",
        },
        not_found: {
            heading: "Record expense categories here",
            sub_heading: "This allows you to classify your purchases and have better visibility on your margin"
        },
    },
    banks: {
        nav_title: "Banks",
        banks: "Banks",
        add_bank_btn: "Add a bank",
        refreshing_transactions: "Refreshing last transactions",
        not_found: {
            heading: "You do not have any bank connected",
            sub_heading: "Start now, connect the bank and synchronize your account",
        },
        reconnect: "Reconnect",
        select_accounts: "Select accounts",
        current_balance: "Current balance",
        last_sync_time: "Last sync time",
        pending_count: "{{count}} pending",
        transactions: "transactions",
        setting_modal: {
            error: {
                required: "At least one account must be enabled"
            },
            deleted: "Deleted",
            bank_settings: "Bank settings",
            bank_accounts_sync: "Bank accounts to sync",
            cancel_btn: "Cancel",
            delete_btn: "Delete",
            save_btn: "Save",
        },
        list: {
            deleted: "Deleted",
            ignored: "Ignored",
            restored: "Restored",
            delete_payment: "Delete payment?",
            want_to_delete_payment: "Do you want to delete payment?",
            delete_expense: "Delete expense",
            want_to_delete_expense: "Do you want to delete expense?",
            search_invoice_number: "Search expense...",
            selected: "Selected",
            delete: "Delete",
            tabs: {
                pending: "Pending transactions",
                associated: "Associated transactions",
                ignored: "Ignored transactions",
            },
            columns: {
                date: "Date",
                type: "Type",
                types: {
                    expense: "Expense",
                    income: "Income",
                    null: ""
                },
                amount: "Amount",
                description: "Description",
                edit: "Edit",
                delete: "Delete",
                restore: "Restore",
                ignore: "Ignore",
                save: "Save",
                pay_bill: "Pay a bill",
                create_income: "Create an income",
                associate: "Associate",
            },
            delete_popup: {
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            ignore_popup: {
                heading: "Warning",
                sub_heading: "Selected transactions will be ignore.",
                cancel_btn: "Cancel",
                delete_btn: "Ignore",
            },
            pay_modal: {
                transaction_link_desc: "This income is linked to a bank transaction and some elements cannot be modified",
                error: {
                    amount: "Amount is required",
                    date: "Date is required",
                    method: "Method is required",
                    invoice: "Invoice is required",
                    amount_min_max: "Allowed between {{min}} to {{max}}",
                },
                create_payment_success: "The payment has been created",
                update_payment_success: "The payment has been updated",
                invoice_to_xxx: "Invoice to {{name}}",
                payment: "Payment",
                loading: "Loading...",
                no_invoice: "No invoice found",
                invoice_to_paid: "Invoice to be paid",
                date: "Date",
                amount: "Amount",
                label: "Label",
                payment_mode: "Payment mode",
                notes: "Notes",
                note_by_cheque: "Note on cheque, client, ...",
                cancel_btn: "Cancel",
                view_invoice_btn: "View invoice",
                view_credit_note_btn: "View credit note",
                save_btn: "Save",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                }
            },
            toolbar: {
                date: "Date",
                start_date: "Start date",
                end_date: "End date",
                clear: "Clear",
            },
            date_filters: {
                today: "Today",
                yesterday: "Yesterday",
                this_week: "This week",
                last_week: "Last week",
                this_month: "This month",
                last_month: "Last month",
                last_30_days: "Last 30 days",
                last_90_days: "Last 90 days",
                last_12_months: "Last 12 months",
                this_year: "This year",
                last_year: "Last year",
                absolute: "Absolute",
                relative: "Relative",
                between: "Between",
                is_after: "Is after",
                is_before: "Is before",
                all_period: "All period",
            },
        },
    },
    bank_rules: {
        nav_title: "Rules",
        list: {
            rules: "Rules",
            banks_rules: "Banks rules",
            new_rule_btn: "New rule",
            deleted: "Deleted",
            disabled: "Rule disabled",
            enabled: "Rule enabled",
            not_found: {
                heading: "Record your bank rules information here",
                sub_heading: "The detailed view brings together all the key information for your business",
            },
            name: "Name",
            type: "Type",
            action: "Action",
            status: "Status",
            types: {
                expense: "Expense",
                income: "Income",
                null: ""
            },
            ignored: "Ignored",
            associated_transaction: "Associate the transaction",
            delete_popup: {
                heading: "Warning",
                description: "Are you sure you want to delete the bank rule?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            status_labels: {
                enabled: "Enabled",
                disabled: "Disabled",
            },
            edit_menu: {
                disable: "Disable",
                enable: "Enable",
                modify: "Modify",
                delete: "Delete",
                edit: "Edit"
            }
        },
        edit: {
            updated: "Rule updated",
            created: "Rule created",
            rule_management: "Rule management",
            cancel_btn: "Cancel",
            update_btn: "Update",
            create_btn: "Create",
            error: {
                not_found: "Not found",
                name: "Name is required",
                type: "Type is required",
                back_account: "Bank account required",
                operator: "Operator is required",
                value: "Value is required",
                method: "Payment method required",
                tax_rate: "Tax rate is required",
                activity: "Activity is required",
                supplier: "Supplier is required",
                client: "Client is required",
                title: 'Title is required',
                country: "Country is required",
            },
            inc_exp_labels: {
                expense: "Expense",
                income: "Income",
            },
            name: "Name",
            operation: "Operation",
            apply_all_accounts: "Apply to all accounts",
            apply_labels: {
                yes: "Yes",
                no: "No"
            },
            list_accounts: "List of accounts",
            terms: "Terms",
            validation_conditions: "Validate all conditions",
            matching_labels: {
                yes: "Yes",
                no: "No"
            },
            type_of_payment: "Type of payment",
            and: "AND",
            or: "OR",
            add_line: "Add a line",
            action: "Action",
            ignore_transaction: "Ignore the transaction",
            action_labels: {
                yes: "Yes",
                no: "No"
            },
            payment_method: "Payment method",
            nature_description: "Nature/Description",
            activity: "Activity",
            client: "Client",
            no_client_found: "No Client Found",
            vat_liable: "VAT Liable",
            taxes_options: {
                yes: "Yes",
                no: "No"
            },
            title: "Title",
            vat: "VAT",
            no_results_found: "No results found.",
            type: "Type",
            supplier: "Supplier",
            country_of_origin: "Country of origin",
            choose_a_country: "Choose a country",
            payment_methods: {
                bank_transfer: "Bank transfer",
                cash: "Cash",
                check: "Check",
                cesu: "Cesu",
                direct_debit: "Direct debit",
                credit_card: "Credit card",
                other: "Other",
                paypal: "PayPal",
                gift_card: "Gift card",
                null: ""
            },
        }
    },
    clients: {
        nav_title: "Clients",
        list: {
            nav_title: "Clients",
            list_clients: "List of clients",
            export_btn: "Export",
            import_btn: "Import",
            new_client_btn: "New Client",
            success: "Success",
            deleted: "Deleted",
            search_clients: "Search clients",
            selected: "Selected",
            delete: "Delete",
            toolbar: {
                country: "Country",
                clear: "Clear",
            },
            import_popup: {
                heading: "Import customers",
                subheading: "Select your file containing the list of customers to import",
                validate_btn: "Validate",
                cancel_btn: "Cancel",
                drop_csv_here: "Drop your CSV file here",
                import_alert: "To learn more about the steps to follow, please read this article:",
            },
            not_found: {
                heading: "Save your customer information here",
                sub_heading: "The detailed view brings together all the key information for your business",
            },
            delete_popup: {
                heading: "Delete",
                description: "Are you sure want to delete {{count}} items?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            tabs: {
                all: "All",
                individual: "Individual",
                enterprise: "Business",
            },
            columns: {
                name: "Name",
                company: "Company",
                address: "Address",
                phone: "Phone",
                email: "Email",
                code: "Client code",
            }
        },
        view: {
            not_found: "No client found",
            deleted: "Deleted",
            view_client: "View client",
            edit_btn: "Edit",
            delete_btn: "Delete",
            create_btn: "Create",
            create_menu: {
                invoice: "Invoice",
                quote: "Quote",
            },
            delete_popup: {
                heading: "Warning",
                description: "Do you want to delete this client?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            address: "Address",
            email: "Email",
            phone: "Phone",
            revenue_month: "Revenue of the month",
            revenue_year: "Revenue of the year",
            note: {
                add_note_btn: "Add a note",
                note_added: "Note Added",
                note_updated: "Note Updated",
                saved_notes: "Notes",
                no_notes_yet: "There are no notes here yet",
                create_first_note: "Create your first note",
                delete_btn: "Delete",
                edit_btn: "Edit",
                delete_popup: {
                    heading: "Warning",
                    description: "Are you sure you want to delete the note?",
                    cancel_btn: "Cancel",
                    delete_btn: "Delete",
                },
                model: {
                    error: {
                        title_require: "Title require",
                        content_require: "Content require",
                    },
                    edit_note: "Edit note",
                    add_note: "Add new note",
                    title: "Title",
                    description: "Description",
                    cancel_btn: "Cancel",
                    save_btn: "Save note",
                }
            },
            docs: {
                doc_deleted: "Document deleted",
                doc_delete_fail: "Document delete fail",
                doc_download_fail: "Document download fail",
                documents: "Documents",
                select_files: "Select files",
            },
            invoices: {
                heading: "Invoices",
                subheading: "List of invoices issued for this client",
                sent: "Successfully sent!",
                columns: {
                    reference_date_labels: {
                        date: "Date",
                        issue_date: "Date",
                        paid_date: "Paid date",
                        execution_date: "Execution date",
                        refund_date: "Refund date",
                    },
                    date: "Date",
                    reference: "Reference",
                    client: "Client",
                    subject: "Subject",
                    total_exc_taxes: "Total Excl. Taxes",
                    total_inc_taxes: "Total Incl. Taxes",
                    status: "Status",
                    status_tag: {
                        pending: "Pending",
                        issued: "Issued",
                        paid: "Paid",
                        not_paid: "Not paid",
                        cancelled: "Cancelled",
                        overdue: "Overdue",
                        draft: "Draft",
                        accepted: "Accepted",
                        refused: "Refused",
                        declined: "Declined",
                        un_paid: "Un paid",
                        canceled: "Cancelled",
                        closed: "Closed",
                        null: ""
                    },
                },
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                }
            },
            quotes: {
                sent: "Successfully sent!",
                heading: "Quotes",
                subheading: "List of quotes issued for this client",
                send_modal: {
                    error: {
                        email: "Email address is required",
                        title: "Subject is required",
                        body: "Body is required",
                        invalid_emails: "Invalid email addresses",
                    },
                    sent: "Successfully sent!",
                    subject: "Email subject",
                    email: "Email address",
                    multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                    send: "Send",
                    cancel_btn: "Cancel",
                    send_btn: "Send",
                },
                columns: {
                    date: "Date",
                    reference: "Reference",
                    client: "Client",
                    subject: "Subject",
                    total_exc_taxes: "Total Excl. Taxes",
                    total_inc_taxes: "Total Incl. Taxes",
                    status: "Status",
                    status_tag: {
                        pending: "Pending",
                        issued: "Issued",
                        paid: "Paid",
                        not_paid: "Not paid",
                        cancelled: "Cancelled",
                        overdue: "Overdue",
                        draft: "Draft",
                        accepted: "Accepted",
                        refused: "Refused",
                        declined: "Declined",
                        un_paid: "Un paid",
                        canceled: "Cancelled",
                        closed: "Closed",
                        null: ""
                    },
                }
            }
        },
        edit: {
            create_client: "Create client",
            create_client_search: "Create {{search}}",
            search_create_clients: "Search or create client",
            not_found: "No client found",
            edit: "Edit client",
            new: "Create a new client",
            created: "Client created",
            updated: "Client updated",
            cancel_btn: "Cancel",
            update_client_btn: "Update client",
            create_client_btn: "Create client",
            error: {
                country: "Country is required",
                last_name: "Last name is required",
                company_name: "Company name is required",
                address: "Address is required",
                city: "City is required",
                zip: "Zip is required",
            },
            client_type_labels: {
                individual: "Individual",
                business: "Company",
            },
            company_name_input_label: "Company name",
            vat_number_input_label: "VAT number",
            registration_number_input_label: "Registration number",
            last_name_input_label: "Last name",
            first_name_input_label: "First name",
            email_input_label: "Email",
            telephone_input_label: "Telephone",
            code_input_label: "Client code",
            address_input_label: "Address",
            additional_address_input_label: "Additional address",
            zip_input_label: "Zip",
            city_input_label: "City",
            country_input_label: "Country",
            choose_a_country: "Choose a country",
            contact_company: "Contact in the company",
            last_clients: "Last clients",
        }
    },
    suppliers: {
        nav_title: "Suppliers",
        list: {
            nav_title: "Suppliers",
            list_suppliers: "List of suppliers",
            new_supplier: "New supplier",
            deleted: "Deleted",
            selected: "Selected",
            delete: "Delete",
            not_found: {
                heading: "Record your supplier information here",
                sub_heading: "The detailed view brings together all the key information for your business",
            },
            search_suppliers: "Search suppliers",
            toolbar: {
                clear: "Clear",
            },
            columns: {
                name: "Name",
                address: "Address",
                phone: "Phone",
                email: "Email"
            },
            delete_popup: {
                heading: "Delete",
                description: "Are you sure want to delete {{count}} items?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
        },
        view: {
            not_found: "No Supplier found",
            deleted: "Supplier deleted",
            view_supplier: "View supplier",
            edit_btn: "Edit",
            delete_btn: "Delete",
            create_expense_btn: "Create expense",
            delete_popup: {
                heading: "Warning",
                description: "Do you want to delete this supplier?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            address: "Address",
            email: "Email",
            phone: "Phone",
            revenue_month: "Revenue of the month",
            revenue_year: "Revenue of the year",
            note: {
                add_note_btn: "Add new note",
                note_added: "Note Added",
                note_updated: "Note Updated",
                saved_notes: "Notes",
                no_notes_yet: "There are no notes here yet",
                create_first_note: "Create your first note",
                delete_btn: "Delete",
                edit_btn: "Edit",
                delete_popup: {
                    heading: "Warning",
                    description: "Are you sure you want to delete the note?",
                    cancel_btn: "Cancel",
                    delete_btn: "Delete",
                },
                model: {
                    error: {
                        title_require: "Title require",
                        content_require: "Content require",
                    },
                    edit_note: "Edit note",
                    add_note: "Add new note",
                    title: "Title",
                    description: "Description",
                    cancel_btn: "Cancel",
                    save_btn: "Save note",
                }
            },
            docs: {
                doc_deleted: "Document deleted",
                doc_delete_fail: "Document delete fail",
                doc_download_fail: "Document download fail",
                documents: "Documents",
                select_files: "Select files",
            },
            expenses: {
                heading: "Expenses",
                subheading: "List of expenses saved for this supplier",
                columns: {
                    date: "Date",
                    supplier: "Supplier",
                    label: "Label",
                    total_exc_taxes: "Total Excl. Taxes",
                    total_inc_taxes: "Total Incl. Taxes",
                    payment_method: "Payment method",
                    attachments: "Attachments",
                    payment_methods: {
                        bank_transfer: "Bank transfer",
                        cash: "Cash",
                        check: "Check",
                        cesu: "Cesu",
                        direct_debit: "Direct debit",
                        credit_card: "Credit card",
                        other: "Other",
                        paypal: "PayPal",
                        gift_card: "Gift card",
                        null: ""
                    },
                    yes: "Yes",
                    no: "No",
                }
            }
        },
        edit: {
            create_supplier: "Create Supplier",
            create_supplier_search: "Create {{search}}",
            search_create_suppliers: "Search or create supplier",
            not_found: "No Supplier found",
            edit: "Edit supplier",
            new: "Create a new supplier",
            created: "Supplier Created",
            updated: "Supplier Updated",
            cancel_btn: "Cancel",
            create_btn: "Create",
            update_supplier_btn: "Update Supplier",
            create_supplier_btn: "Create Supplier",
            error: {
                country: "Country is required",
                company_name: "Company name is required",
                invalid_emails: "Invalid email addresses",
            },
            company_name_input_label: "Company name",
            address_input_label: "Address",
            address_supplementary_input_label: "Additional address",
            zip_input_label: "Zip",
            city_input_label: "City",
            country_input_label: "Country",
            choose_a_country: "Choose a country",
            contact_company: "Contact in the company",
            last_name_input_label: "Last name",
            first_name_input_label: "First name",
            email_input_label: "Email",
            phone_input_label: "Telephone",
            last_suppliers: "Last suppliers",
        }
    },
    articles: {
        nav_title: "Articles",
        list: {
            heading: "Articles",
            export_btn: "Export",
            import_btn: "Import",
            new_btn: "New article",
            success: "Success",
            selected: "Selected",
            sales: {
                goods: "Sales of goods",
                services: "Services",
                other_services: "Other services",
                disbursement_fees: "Disbursement fees",
                null: ""
            },
            import_popup: {
                import_articles: "Import articles",
                import_select_filters: "Select your file containing the list of items to import",
                validate_btn: "Validate",
                cancel_btn: "Cancel",
                drop_csv_here: "Drop your CSV file here",
                import_alert: "To learn more about the steps to follow, please read this article:",
            },
            not_found: {
                sub_heading: "While editing your invoices, item suggestions will appear to automatically fill in your lines",
                heading: "Record your articles information here",
            },
            search_articles: "Search article...",
            deleted: "Deleted",
            delete_popup: {
                heading: "Delete",
                description: "Are you sure want to delete {{count}} items?",
                delete_btn: "Delete",
                cancel_btn: "Cancel",
            },
            columns: {
                reference: "Reference",
                title: "Title",
                description: "Description",
                type: "Type",
                origin: "Origin",
                price: "Price",
                maker: "Maker",
            },
            toolbar: {
                clear: "Clear",
            },
        },
        edit: {
            updated: "Article updated",
            created: "Article created",
            deleted: "Deleted",
            article: "Article",
            cancel_btn: "Cancel",
            delete_btn: "Delete",
            update: "Update",
            create: "Create",
            delete_popup: {
                warning: "Warning",
                want_to_delete: "Do you want to delete article?",
                cancel_btn: "Cancel",
                delete_btn: "Delete",
            },
            error: {
                not_found: "No article found",
                title: 'Title is required',
                price_min: "Minimum price allowed {{amount}}",
                price: "Price is required",
                type: "Type is required",
                origin_country_code: "Country is required",
                tax_rate: "Required",
            },
            info_section: {
                sku_input_label: "SKU",
                title_input_label: "Title",
                maker_input_label: "Maker",
                description_input_label: "Description",
            },
            format_section: {
                price_format: "Price Format",
                second_hand_article: "Second hand article",
                taxes_included_labels: {
                    vat_incl: "VAT incl.",
                    vat_excl: "VAT excl.",
                },
                second_hand_good_labels: {
                    yes: "Yes",
                    no: "No"
                }
            },
            vat_section: {
                sale_price_inc_tax_input_label: "Sale price including tax",
                price_exc_tax_input_label: "Price excluding Tax",
                vat_input_label: "VAT",
                no_results_found: "No results found.",
                type_input_label: "Type",
                country_dep_product_input_label: "Country of departure of the product",
                choose_a_country: "Choose a country",
                eco_participation_input_label: "Eco-participation",
                purchase_price_input_label: "Purchase price",
            }
        }
    },
    integration: {
        nav_title: "Integrations",
        heading: "Integrations",
        add_integration: "Add integration",
        is_new: "Beta",
        paused: "Paused",
        resumed: "Resumed",
        disconnected: "Disconnected",
        update: "Update",
        updated: "Updated configuration",
        integrated_services: "Integrated Services",
        edit_btn: "Edit",
        sync_running: "The synchronization is running",
        last_sync: "Last synchronization was {{time}}",
        finalize_sync: "Finalize Synchronization",
        waiting_sync: "Waiting for the first synchronization",
        find_services: "Find services...",
        order_id_not_found: "Order not found, make sure you typed the right number",
        synchronization_running: "Synchronization running",
        name: "Name",
        name_updated: "Name updated",
        error: {
            activity: "Activity is required",
            must_allowed: "Min {{amount}} allowed",
            tax_rate: "Tax rate is required",
            tax_rule: "Tax rule is required",
            sales_type: "Sales type is required",
            country: "Country is required",
            expense_type: "Expense type is required",
            invalid_emails: "Invalid email addresses",
            synchronization_type: "Synchronization type is required",
        },
        edit_menu: {
            disconnect: "Disconnect",
            configuration: "Configuration",
            reconnect: "Reconnect",
            force_sync: "Force synchronization",
            pause_sync: "Deactivate synchronization",
            resume_sync: "Activate synchronization",
        },
        resume_popup: {
            title: "Activate this service?",
            description: "If you sync this payment account and a store using this same account is synced, your invoices will be issued twice.",
            cancel_btn: "Cancel",
            enable_btn: "Enable",
        },
        no_record: {
            heading: "Integrations",
            connect_info: "Connect your Bizyness account with other online services to automate the creation of your invoices.",
            begin_integration_btn: "Begin integration",
        },
        connection_status: {
            connected: "Service connected, finalize the connection to get started",
            refreshed: "Connection refreshed",
            error: "The service cannot be connected, contact us for more details",
            error_paypal_requirements_email: "Unable to connect! Please confirm your email address on https://www.paypal.com/businessprofile/settings in order to receive payments",
            error_paypal_requirements_receivable: "Unable to connect! You currently cannot receive payments due to restriction on your PayPal account",
            error_webhook: "The service cannot be connected because Bizyness is already installed on it",
        },
        categories: {
            ecommerce: "E-commerce",
            automation: "Automation",
            payment_gateway: "Payment",
        },
        descriptions: {
            wizishop: "Automatically create and send your invoices from thousands of online services",
            shopify: "Automatically create and send your invoices from thousands of online services",
            stripe: "Automatically create and send invoices for your online payments with Stripe",
            paypal: "Automatically create and send invoices for your online payments with PayPal",
            woocommerce: "Automatically create and send your invoices from thousands of online services",
            zapier: "Automatically create and send your invoices from thousands of online services",
        },
        status: {
            enabled: "Invoices synchronized",
            pending: "Pending",
            disabled: "Invoices not synchronized"
        },
        list: {
            all_integrations: "All integrations",
            ecommerce: "E-commerce",
            automation: "Automation",
            payment_gateway: "Payment",
        },
        woocommerce: {
            synchronization: "To know the identifier, open the order in your administration interface and copy the order number (without the # character)",
            heading: "Woocommerce Login",
            description: "Indicate the URL of your store to install the Bizyness application",
            store_url_input_label: "Store URL",
            if_error_pre: "If you have an error while synchronizing your store, you can ",
            if_error_link: "manually configure the connection.",
            follow_instruction: "Please follow the instructions on ",
            this_page: "this page ",
            fill_the_items: "and fill in the items below for the connection.",
            customer_key_input_label: "Customer key",
            customer_secret_input_label: "Customer secrecy",
            connect_btn: "Connect",
            previous_btn: "Previous",
            order_id: "Order ID",
            order_id_error: "Order ID required",
            error: {
                store: "Store is required",
                invalid_url: "Invalid URL",
                consumer_key: "Consumer key is required",
                consumer_secret: "Consumer Secret is required",
            },
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect this store?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        wizishop: {
            synchronization: "Indicate the order number without the leading '0' digits. For example, specify 123 for command 0000123",
            heading: "Wizishop Login",
            description: "Enter your login credentials and choose the store to synchronize",
            info_use: "NB: This information is used to connect your store for the first time and is not kept",
            identifier: "WiziShop identifiers",
            shop_to_sync: "Shop to synchronize",
            order_id: "Order ID",
            order_id_error: "Order ID required",
            password_input_label: "Password",
            email_input_label: "Email",
            login_btn: "Login",
            connect_btn: "Connect",
            previous_btn: "Previous",
            error: {
                invalid_email: "Email is invalid",
                email: "Email is required",
                password: "Password is required",
                store: "Store is required",
                can_not_connect: "Cannot connect, please verify your email/password",
            },
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect this store?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        shopify: {
            synchronization: "To know the identifier, open the order in your administration interface and copy the last part of the URL (https://admin.shopify.com/store/myshop/orders/XXXXXXXXXX)",
            heading: "Shopify Login",
            description: "You will be redirected to the Shopify Store to add Bizyness to your store. The price indicated on the Shopify page is indicative, you will not be charged by Shopify for the use of the Bizyness application.",
            connect_btn: "Connect",
            previous_btn: "Previous",
            order_id: "Order ID",
            order_id_error: "Order ID required",
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect this store?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        stripe: {
            synchronization: "To know the identifier, open the payment in your Stripe administration interface and copy the payment number starting with 'ch_'",
            heading: "Stripe Login",
            description: "Click the button below to link or create your Stripe account",
            order_id: "Payment ID",
            order_id_error: "Payment ID required",
            connect_btn: "Connect",
            previous_btn: "Previous",
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect this account?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        paypal: {
            synchronization: "To know the identifier, open the payment in your PayPal administration interface and copy the transaction number",
            heading: "PayPal Login",
            description: "Click the button below to link or create your PayPal account",
            order_id: "Payment ID",
            order_id_error: "Payment ID required",
            connect_btn: "Connect",
            previous_btn: "Previous",
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect this account?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        zapier: {
            heading: "Zapier Login",
            description: "Invitation required",
            integration_info: "This integration is currently in beta. You can access the private Zap Bizyness at this address:",
            order_id: "Order ID",
            order_id_error: "Order ID required",
            connect_btn: "Connect",
            previous_btn: "Previous",
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect the zapier?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        amazon: {
            synchronization: "Indicate the Amazon order number, for example 123-4567890-1234567",
            heading: "Amazon Login",
            description: "Select your marketplace to connect your Amazon account",
            marketplace: "Marketplace",
            order_id: "Order ID",
            order_id_error: "Order ID required",
            connect_btn: "Connect",
            previous_btn: "Previous",
            error: {
                required: "Required",
            },
            disconnect_popup: {
                title: "Disconnect",
                disconnect_notice: "Do you want to disconnect the amazon?",
                cancel_btn: "Cancel",
                disconnect_btn: "Disconnect",
            },
        },
        force_synchronization: {
            synchronization_running: "Synchronization running",
            force_sync: "Force synchronization",
            enter_valid_number: "Enter a valid number to force the synchronization for missing invoices",
            options: "Options",
            synchronize_issue: "Synchronize and issue next invoices",
            cancel_btn: "Cancel",
            start_synchronization_btn: "Start synchronization",
        },
        configuration_popup: {
            cancel_btn: "Cancel",
            save_changes_btn: "Save changes",
            section_labels: {
                identity: "Identity",
                invoices: "Mode",
                vat: "VAT",
                options: "Options",
            },
            identity_section: {
                identity_info: "Synchronized invoices will be associated with the chosen activity. By separating the activities within your company you will be able to have more precise reports.",
                activity_input_label: "Activity",
                name: "Name",
                email: "Email",
                store_name: "Store name",
                store_url: "Store URL",
            },
            invoice_section: {
                lang_inv_email: "Language of invoices and emails",
                tooltips: {
                    lang_inv_email: "Translate invoices and emails in the customer's language or not",
                    invoices_subject: "Indicate the subject of the generated invoices",
                    products_metadata: "Indicate the product metadata to include in invoice lines",
                    include_sku: "Activate this option to include the SKU of the products in the 'SKU' column of the invoices",
                    include_notes: "Enable this option to include order notes/comments on invoices",
                    include_pay_instruction: "Activate this option to add payment instructions (RIB or other) in invoices",
                    payment_method_included: "Activate this option to add used payment method in invoices",
                    include_terms: "Activate this option to add the legal terms of payment in the invoices",
                    sync_invoice_type: "Generate invoice",
                    sync_payment_type: "Import payment",
                    draft: "Activate this option to create invoices in draft",
                    draft_tags: "Indicate the tags for orders that should be created as drafts",
                },
                sync_info: "By default, Bizyness allows you to generate invoices and credit notes for your store's orders. If you prefer not to manage invoices, you can choose to record only the amounts of your orders in your sales ledger.",
                sync_invoice_type: "Generate invoices",
                sync_payment_type: "Import payments",
                language_input_label: "Language",
                products_meta_groups: {
                    standard_metafields: "Standard",
                    custom_metafields: "Custom",
                },
                add_custom: "Add '{{input}}'",
                products_metadata_input_label: "Products metadata",
                include_sku: "SKU",
                include_notes: "Notes",
                include_pay_instruction: "Payment instructions",
                payment_method_included: "Payment mode",
                include_terms: "Payment terms",
                payment_delay_input_label: "Payment delay",
                lang_labels: {
                    use_customer_language: "Use customer language",
                    set_language: "Set language",
                },
                payment_delay_labels: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: 'None',
                },
                draft: "Create drafts",
                draft_tags_input_label: "Orders to include",
            },
            vat_section: {
                vat_info: "Bizyness can automatically manage your shop's VAT rates according to your tax regime, your activity and the location of your customers. This option is recommended for most online activities and takes OSS/IOSS into account. If you benefit from specific taxation rules or if you sell products with a reduced VAT rate, Bizyness can use the VAT rates defined in your shop or specific taxation rules.",
                auto_vat: "Auto VAT",
                shop_vat: "Shop VAT",
                custom_vat: "Custom VAT",
                tva_exemption: "VAT exemption",
                exemption_text_input_label: "Exemption Text",
                vat_rate_input_label: "VAT Rate",
                default_vat_rate_input_label: "VAT rates for items",
                shipping_vat_rate_input_label: "VAT rule for shipping costs",
                tooltips: {
                    auto_vat: "Bizyness automatically manages the VAT, without taking into account the configuration of your store",
                    shop_vat: "Bizyness uses the VAT rates defined in your store",
                    custom_vat: "Bizyness uses the rules you define",
                    tva_exemption: "Activate this option if all your sales are exempt from VAT",
                    tax_exemption_text: "Indicate a VAT exemption message",
                    tax_rate: "Indicate a fixed VAT rate",
                    default_vat_rate: "Select the default VAT rate for items",
                    shipping_vat_rate: "Select the method for applying the VAT rate to shipping costs",
                },
                shipping_rate: {
                    article_rate: "Articles' rate",
                    default_rate: "Standard rate",
                },
                taxes_options: {
                    yes: "Yes",
                    no: "No"
                },
            },
            option_section: {
                type_of_sale_input_label: "Type of sale",
                departure_country_input_label: "Departure country of products",
                choose_a_country: "Choose a country",
                synchronization_mode_input_label: "Synchronization mode",
                order_to_exclude_input_label: "Orders to exclude",
                no_tax_gift: "No tax on gift cards",
                send_invoice: "Send invoice",
                synchronize_payments: "Synchronize payments",
                expense_type_input_label: "Expense type",
                synchronize_articles: "Synchronize articles",
                price_format_input_label: "Price Format",
                additional_recipient_invoices_input_label: "Additional recipient of the invoices",
                multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                tooltips: {
                    sales_type: "Indicate the type you wish to apply to your invoices",
                    synchronization_mode: "Indicate when you want to issue invoices",
                    order_to_exclude: "Indicate the tags of the orders you do not want to synchronize",
                    no_tax_gift: "Activate this option if you sell gift cards without applying VAT",
                    send_invoice: "Activate this option to automatically send issued invoices to your customers",
                    synchronize_payments: "When orders are paid through a platform such as Stripe or PayPal, transaction fees are added to your spend",
                    expense_type: "Specify the category to use to record bank charges",
                    synchronize_articles: "Activate this option to synchronize the articles of your shop",
                    products_taxes_included: "Indicate whether the price of your items includes VAT or not",
                    additional_recipient: "Indicate the recipient, other than the customer, who will receive all invoices issued",
                    products_tax_rate: "Indicate the default VAT rate"
                },
                price_formats: {
                    false: "Price excluding taxes",
                    true: "Price including taxes"
                },
                products_tax_rate: "VAT rate",
                activity_types: {
                    goods: "Distance sales",
                    services: "Commercial or artisanal services(BIC)",
                    other_services: "Other services or liberal activities(BNC)",
                },
                synchronization_mode_labels: {
                    issued: "When order is issued",
                    paid: "When order is paid",
                    fulfilled: "When order is fulfilled"
                },
            },
        },
        synchronize_popup: {
            next_btn: "Next",
            finalize_btn: "Finalize",
            previous_btn: "Previous",
            initialized: "Initialized",
            section_labels: {
                identity: "Identity",
                invoices: "Mode",
                vat: "VAT",
                options: "Options",
                synchronization: "Synchronization",
            },
            identity_section: {
                identity_info: "Synchronized invoices will be associated with the chosen activity. By separating the activities within your company you will be able to have more precise reports.",
                activity_input_label: "Activity",
                name: "Name",
                email: "Email",
                store_name: "Store name",
                store_url: "Store URL",
            },
            invoice_section: {
                lang_inv_email: "Language of invoices and emails",
                tooltips: {
                    lang_inv_email: "Translate invoices and emails in the customer's language or not",
                    invoices_subject: "Indicate the subject of the generated invoices",
                    products_metadata: "Indicate the product metadata to include in invoice lines",
                    include_sku: "Activate this option to include the SKU of the products in the 'SKU' column of the invoices",
                    include_notes: "Enable this option to include order notes/comments on invoices",
                    include_pay_instruction: "Activate this option to add payment instructions (RIB or other) in invoices",
                    payment_method_included: "Activate this option to add used payment method in invoices",
                    include_terms: "Activate this option to add the legal terms of payment in the invoices",
                    sync_invoice_type: "Generate invoice",
                    sync_payment_type: "Import payment",
                    draft: "Activate this option to create invoices in draft",
                    draft_tags: "Indicate the tags for orders that should be created as drafts",
                },
                sync_info: "By default, Bizyness allows you to generate invoices and credit notes for your store's orders. If you prefer not to manage invoices, you can choose to record only the amounts of your orders in your sales ledger.",
                sync_invoice_type: "Generate invoices",
                sync_payment_type: "Import payments",
                language_input_label: "Language",
                products_meta_groups: {
                    standard_metafields: "Standard",
                    custom_metafields: "Custom",
                },
                add_custom: "Add '{{input}}'",
                products_metadata_input_label: "Products metadata",
                include_sku: "SKU",
                include_notes: "Notes",
                include_pay_instruction: "Payment instructions",
                payment_method_included: "Payment mode",
                include_terms: "Payment terms",
                payment_delay_input_label: "Payment delay",
                lang_labels: {
                    use_customer_language: "Use customer language",
                    set_language: "Set language",
                },
                payment_delay_labels: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: 'None',
                },
                draft: "Create drafts",
                draft_tags_input_label: "Orders to include",
            },
            vat_section: {
                vat_info: "Bizyness can automatically manage your shop's VAT rates according to your tax regime, your activity and the location of your customers. This option is recommended for most online activities and takes OSS/IOSS into account. If you benefit from specific taxation rules or if you sell products with a reduced VAT rate, Bizyness can use the VAT rates defined in your shop or specific taxation rules.",
                auto_vat: "Auto VAT",
                shop_vat: "Shop VAT",
                custom_vat: "Custom VAT",
                tva_exemption: "VAT exemption",
                exemption_text_input_label: "Exemption Text",
                vat_rate_input_label: "VAT Rate",
                default_vat_rate_input_label: "VAT rates for items",
                shipping_vat_rate_input_label: "VAT rule for shipping costs",
                tooltips: {
                    auto_vat: "Bizyness automatically manages the VAT, without taking into account the configuration of your store",
                    shop_vat: "Bizyness uses the VAT rates defined in your store",
                    custom_vat: "Bizyness uses the rules you define",
                    tva_exemption: "Activate this option if all your sales are exempt from VAT",
                    tax_exemption_text: "Indicate a VAT exemption message",
                    tax_rate: "Indicate a fixed VAT rate",
                    default_vat_rate: "Select the default VAT rate for items",
                    shipping_vat_rate: "Select the method for applying the VAT rate to shipping costs",
                },
                shipping_rate: {
                    article_rate: "Articles' rate",
                    default_rate: "Standard rate",
                },
                taxes_options: {
                    yes: "Yes",
                    no: "No"
                },
            },
            option_section: {
                type_of_sale_input_label: "Type of sale",
                departure_country_input_label: "Departure country of products",
                choose_a_country: "Choose a country",
                synchronization_mode_input_label: "Synchronization mode",
                order_to_exclude_input_label: "Orders to exclude",
                no_tax_gift: "No tax on gift cards",
                send_invoice: "Send invoice",
                synchronize_payments: "Synchronize payments",
                expense_type_input_label: "Expense type",
                synchronize_articles: "Synchronize articles",
                price_format_input_label: "Price Format",
                additional_recipient_invoices_input_label: "Additional recipient of the invoices",
                multi_email_info: "You can send to multiple recipients by separating emails with a comma",
                tooltips: {
                    sales_type: "Indicate the type you wish to apply to your invoices",
                    synchronization_mode: "Indicate when you want to issue invoices",
                    order_to_exclude: "Indicate the tags of the orders you do not want to synchronize",
                    no_tax_gift: "Activate this option if you sell gift cards without applying VAT",
                    send_invoice: "Activate this option to automatically send issued invoices to your customers",
                    synchronize_payments: "When orders are paid through a platform such as Stripe or PayPal, transaction fees are added to your spend",
                    expense_type: "Specify the category to use to record bank charges",
                    synchronize_articles: "Activate this option to synchronize the articles of your shop",
                    products_taxes_included: "Indicate whether the price of your items includes VAT or not",
                    additional_recipient: "Indicate the recipient, other than the customer, who will receive all invoices issued",
                    products_tax_rate: "Indicate the default VAT rate"
                },
                price_formats: {
                    false: "Price excluding taxes",
                    true: "Price including taxes"
                },
                products_tax_rate: "VAT rate",
                activity_types: {
                    goods: "Distance sales",
                    services: "Commercial or artisanal services(BIC)",
                    other_services: "Other services or liberal activities(BNC)",
                },
                synchronization_mode_labels: {
                    issued: "When order is issued",
                    paid: "When order is paid",
                    fulfilled: "When order is fulfilled"
                },
            },
            sync_section: {
                synchronize_past_orders: "Do you want to synchronize your past orders",
                past_orders_labels: {
                    yes: "Yes",
                    no: "No"
                },
                synchronization_of_orders: "Synchronization of orders",
                fill_id_info: "Enter an order ID below to sync your store from this order to today",
            }
        }
    },
    urssaf_report: {
        nav_title: "Statements of URSSAF",
        heading: "Statements of URSSAF",
        card_label: {
            revenue_of_distance_sales: "Revenue of distance sales:",
            revenue_of_service_deliveries: "Revenue of service deliveries:",
            revenue_of_other_services: "Revenue of other services:",
            estimated_taxes: "Estimated taxes",
            provisional_declaration: "Provisional declaration of",
            declaration: "Declaration of",
            quarter: "Quarter",
        }
    },
    income_expense_report: {
        nav_title: "Incomes/Expenses",
        page_title: "Incomes and Expense Report",
        heading: "Incomes and Expense Report",
        refresh_results: "Refresh results",
        payments: "Payments",
        invoices: "Invoices",
        relative_date_filters: {
            today: "Today",
            yesterday: "Yesterday",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month",
            last_30_days: "Last 30 days",
            last_90_days: "Last 90 days",
            last_12_months: "Last 12 months",
            this_year: "This year",
            last_year: "Last year",
        },
        filters: {
            more: "More filters",
            less: "Less filters",
            from_input_label: "From",
            to_input_label: "To",
            filter_by: "Filter by",
            activity_input_label: "Activity",
            loading: "Loading...",
            no_activity_found: "No activity found",
        },
        reference_date_options: {
            date_of_issue: "Issue date",
            date_of_sale: "Sale date",
            payment_date: "Paid date",
        },
        error: {
            date: "Date is required",
            min_start_date: "End date must be after start date",
        },
        evaluation: {
            net_margin: "Net Margin",
            incomes: "Incomes",
            expenses: "Expenses",
            evolution_profits: "Evolution of profits",
        },
        sales: {
            merchandise_sales: "Merchandise sales",
            comm_or_artisanal_ser: "Commercial or artisanal services (BIC)",
            other_services: "Other services or liberal activities (BNC)",
        },
        distribution: {
            by_country: "Distribution of receipts by country",
            by_expense: "Distribution of expenses",
            no_data: "There is no data to be displayed",
        }
    },
    vat_report: {
        nav_title: "Local VAT",
        heading: "VAT report",
        refresh_results: "Refresh results",
        taxable_trans: "Taxable transactions",
        total_base_exc_tax: "Total Base Excluding Tax",
        rate_percent: "Rate {{rate}} %: ",
        total_vat_collected: "Total VAT Collected",
        deductible_vat: "Deductible VAT",
        total_deductible_vat: "Total Deductible VAT",
        non_taxable_trans: "Non-Taxable Transactions",
        total_without_taxes: "Total without taxes",
        filters: {
            more: "More filters",
            less: "Less filters",
            from_input_label: "From",
            to_input_label: "To",
            filter_by: "Filter by",
            activity_input_label: "Activity",
            loading: "Loading...",
            no_activity_found: "No activity found",
        },
        relative_date_filters: {
            today: "Today",
            yesterday: "Yesterday",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month",
            last_30_days: "Last 30 days",
            last_90_days: "Last 90 days",
            last_12_months: "Last 12 months",
            this_year: "This year",
            last_year: "Last year",
        },
        error: {
            date: "Date is required",
            min_start_date: "End date must be after start date",
        },
    },
    goods_report: {
        nav_title: "Second hand goods",
        heading: "Second hand goods report",
        refresh_results: "Refresh results",
        taxable_trans: "Taxable transactions",
        total_margin: "Total margin",
        total_taxes_collected: "Total taxes collected",
        reference_date_options: {
            date_of_issue: "Issue date",
            date_of_sale: "Sale date",
            payment_date: "Paid date",
        },
        filters: {
            more: "More filters",
            less: "Less filters",
            from_input_label: "From",
            to_input_label: "To",
            filter_by: "Filter by",
            activity_input_label: "Activity",
            loading: "Loading...",
            no_activity_found: "No activity found",
        },
        relative_date_filters: {
            today: "Today",
            yesterday: "Yesterday",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month",
            last_30_days: "Last 30 days",
            last_90_days: "Last 90 days",
            last_12_months: "Last 12 months",
            this_year: "This year",
            last_year: "Last year",
        },
        error: {
            date: "Date is required",
            min_start_date: "End date must be after start date",
        },
    },
    oss_report: {
        nav_title: "OSS/IOSS",
        heading: "OSS/IOSS report",
        refresh_results: "Refresh results",
        list_of_ioss: "List of IOSS operations",
        services_sales: "Services sales",
        sale_of_goods: "Sale of goods",
        list_of_oss: "List of OSS operations",
        download: "Download",
        see_details: "See Details",
        country: "Country",
        vat_rate: "VAT Rate",
        total_HT: "Total HT",
        total_vat: 'Total VAT',
        no_data: "There is no data to be displayed",
        report_sent_mailbox: "The report will be sent to your mailbox in few minutes",
        filters: {
            more: "More filters",
            less: "Less filters",
            from_input_label: "From",
            to_input_label: "To",
            filter_by: "Filter by",
            activity_input_label: "Activity",
            loading: "Loading...",
            no_activity_found: "No activity found",
        },
        relative_date_filters: {
            today: "Today",
            yesterday: "Yesterday",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month",
            last_30_days: "Last 30 days",
            last_90_days: "Last 90 days",
            last_12_months: "Last 12 months",
            this_year: "This year",
            last_year: "Last year",
        },
        error: {
            date: "Date is required",
            min_start_date: "End date must be after start date",
        },
    },
    accounting_report: {
        nav_title: "Accounting",
        heading: "Accounting report",
        refresh_results: "Refresh results",
        download: "Download",
        reference_date_options: {
            date_of_issue: "Issue date",
            date_of_sale: "Sale date",
            payment_date: "Paid date",
        },
        filters: {
            more: "More filters",
            less: "Less filters",
            from_input_label: "From",
            to_input_label: "To",
            filter_by: "Filter by",
            activity_input_label: "Activity",
            loading: "Loading...",
            no_activity_found: "No activity found",
        },
        relative_date_filters: {
            today: "Today",
            yesterday: "Yesterday",
            this_week: "This week",
            last_week: "Last week",
            this_month: "This month",
            last_month: "Last month",
            last_30_days: "Last 30 days",
            last_90_days: "Last 90 days",
            last_12_months: "Last 12 months",
            this_year: "This year",
            last_year: "Last year",
        },
        error: {
            date: "Date is required",
            min_start_date: "End date must be after start date",
            required: "Required",
        },
        configuration: {
            unlink_trans: "Unlink transactions",
            property: "Property",
            value: "Value",
            client_country_extra: {
                _europe: "UE",
                _not_europe: "Outside UE",
            },
            log_code_input_label: "Log Code",
            journal_input_label: "Journal Label",
            account_number_input_label: "Account number",
            account_input_label: "Account Label",
            add_condition_btn: "Add condition",
            delete_line_btn: "Delete line",
            add_line_btn: "Add a line",
            total_including_taxes: {
                heading: "Total Price",
                sub_heading: "Total amount including VAT of the invoice",
            },
            goods_taxes_basis: {
                heading: "Total excl. taxes - Sales of goods",
                sub_heading: "Total Base Amount excluding taxes",
            },
            services_taxes_basis: {
                heading: "Total excl. taxes - Provision of services (BIC)",
                sub_heading: "Total Base Amount excluding taxes",
            },
            other_services_taxes_basis: {
                heading: "Total excl. taxes - Provision of services (BNC)",
                sub_heading: "Total Base Amount excluding taxes",
            },
            shipping: {
                heading: "Shipping cost",
                sub_heading: "Amount excluding VAT of delivery costs",
            },
            taxes: {
                heading: "Total taxes",
                sub_heading: "Total amount of taxes",
            },
            second_hand_goods_margin: {
                heading: "Second hand goods (excl. taxes)",
                sub_heading: "Total Base Amount excluding taxes",
            },
            second_hand_goods_taxes: {
                heading: "Second hand goods taxes",
                sub_heading: "Total amount of taxes",
            },
            gap: {
                heading: "Rounding deviation",
                sub_heading: "Amount of the rounding difference of the Total Base HT and VAT",
            },
        },
        payment_methods: {
            bank_transfer: "Bank transfer",
            cash: "Cash",
            check: "Check",
            cesu: "Cesu",
            direct_debit: "Direct debit",
            credit_card: "Credit card",
            other: "Other",
            paypal: "PayPal",
            gift_card: "Gift card",
            null: ""
        },
        client_types: {
            individual: "Individual",
            company: "Company"
        },
        unlink_labels: {
            yes: "Yes",
            no: "No"
        },
        property_label: {
            vat_rate: "VAT rate",
            client_country: "Selling location",
            payment_mode: "Payment mode",
            client_type: "Client type",
        },
    },
    company_setting: {
        nav_title: "Company",
        updated: "Updated successfully",
        information_heading: "Information",
        save_btn: "Save",
        company_name_input_label: "Company name",
        address_input_label: "Address",
        address_supplementary_input_label: "Address supplementary",
        zip_input_label: "Zip",
        city_input_label: "City",
        country_input_label: "Country",
        registration_type_input_label: "Registration type",
        company_identifier_input_label: "Company identifier",
        vat_number_input_label: "VAT number",
        share_capital_input_label: "Share capital",
        choose_a_country: "Choose a country",
        registration_types: {
            rcs: "RCS",
            exempted: "Exempted",
            rm: "RM",
            rseirl: "RSEIRL",
            rcs_rm: "Dual registration RCS/RM"
        },
        registration_type_labels: {
            rcs: "City of registration RCS",
            rm: "RM registration department number",
            rseirl: "City of registration RSEIRL",
            rcs_rm: "City of RCS registration and RM registration department number (separated by a comma)"
        },
        warranty: "Warranty",
        insurance_company_name_input_label: "Name of insurance company",
        insurance_geographic_coverage_input_label: "Geographic coverage",
        second_hand_goods_selling: "Sale of second-hand goods",
        tax_payment_debit_based: "Option for tax payment based on debits",
        frequency_contributions: "Frequency of URSSAF contributions",
        creation_date_input_label: "Creation date",
        income_tax_discharged: "Income tax discharged",
        beneficiary: "Beneficiary of ACRE",
        error: {
            company: "Company name is required",
            country: "Country is required",
            address: "Address is required",
            city: "City is required",
            must_allowed: "Min {{amount}} allowed",
            share_capital: "Share capital required",
            invalid_date: "Date is invalid",
        },
        insurance_labels: {
            no_warranty: "No warranty",
            defect_insurance: "Defects insurance",
        },
        goods_options: {
            yes: "Yes",
            no: "No"
        },
        debit_based_options: {
            yes: "Yes",
            no: "No"
        },
        beneficiary_labels: {
            yes: "Yes",
            no: "No"
        },
        tax_discharge_labels: {
            yes: "Yes",
            no: "No"
        },
        distribution_labels: {
            monthly: "Monthly",
            quarterly: "Quarterly",
        },
    },
    vat_setting: {
        nav_title: "VAT",
        updated: "Updated successfully",
        status_of_company: "Status of your company",
        save_btn: "Save",
        eligible_to_vat: "Are your eligible to VAT?",
        choose_the_date: "Choose the date",
        liability_date_input_label: "Liability date",
        distance_sales: "Distance sales in Europe",
        alert_sales: "When you make more than €10,000 in distance sales and electronic services in Europe in the year, you must apply the VAT rate of the other Member State. If you have already exceeded this threshold or have opted for OSS, you can indicate this below.",
        oss_registered: "OSS registered",
        registeration_date_input_label: "Registration date",
        threshold_exceed: "10000 € threshold exceeded",
        exceeding_date_input_label: "Exceeding date",
        total_sales_in: "Total amount of sales in Europe",
        imports_in: "Imports in Europe",
        alert_imports: "If you ship goods from third countries to the European Union, to customers residing in Europe, for amounts less than €150, you can subscribe to the IOSS in order to simplify the procedures for paying VAT.",
        ioss_registered: "IOSS registered",
        registeration_date_import_input_label: "Registration date",
        error: {
            date: "Date is required",
            required: "Required",
            invalid_date: "Date is invalid",
            update: "Update fail",
        },
        vat_options: {
            yes: "Yes",
            no: "No"
        },
        oss_registered_labels: {
            yes: "Yes",
            no: "No"
        },
        threshold_exceed_labels: {
            yes: "Yes",
            no: "No"
        },
        ioss_registered_labels: {
            yes: "Yes",
            no: "No"
        },
        date_labels: {
            since_creation_date: "Since creation date",
            another_date: "Since another date"
        },
    },
    activity_setting: {
        nav_title: "Activities",
        list: {
            title: "Activities",
            new_activity_btn: "New activity",
            not_found: {
                img_alt: "Activities information here",
                note: "Activities information here",
                extra_note: "Invoices will be automatically issued with the information indicated, on the scheduled date",
            },
            deleted: "Deleted",
            duplicated: "Duplicated",
            default_label: "Default",
            edit_menu: {
                edit: "Edit",
                duplicate: "Duplicate",
                delete: "Delete",
            },
            duplicate_popup: {
                title: "Duplicate Activity",
                submit_text: "Duplicate Activity",
                name_input_label: "Name",
                cancel_btn: "Cancel",
                error: {
                    name: "Name is required",
                }
            },
            delete_popup: {
                title: "Warning",
                subtitle: "Are you sure you want to delete the activity?",
                delete_btn: "Delete",
                cancel_btn: "Cancel",
            }
        },
        edit: {
            title: "Activity settings",
            cancel_btn: "Cancel",
            save_btn: "Save",
            created: "Activity created",
            updated: "Activity updated",
            general: {
                title: "General",
                activity_name: "Activity name",
                activity_name_input_label: "Edit the activity name",
                delete_btn: "Delete",
                identity: "Identity",
                trade_name_input_label: "Trade name",
                email_input_label: "Email",
                languages: {
                    french: "French",
                    english: "English",
                    german: "German",
                    italian: "Italian",
                    spanish: "Spanish"
                },
                footer_input_label: "Footer",
                payment_methods: {
                    bank_transfer: "Bank transfer",
                    cash: "Cash",
                    check: "Check",
                    cesu: "Cesu",
                    direct_debit: "Direct debit",
                    credit_card: "Credit card",
                    other: "Other",
                    paypal: "PayPal",
                    gift_card: "Gift card",
                    null: ""
                },
                payment_delay_options: {
                    upon_invoice: "Upon receipt",
                    upon_order: "With order",
                    upon_15_net: "15 days net",
                    upon_30_net: "30 days net",
                    upon_45_net: "45 days net",
                    upon_60_net: "60 days net",
                    upon_30_end: "30 days net end of month",
                    upon_45_end: "45 days net end of month",
                    upon_30_end_offset_10: "30 days net end of month, the 10th",
                    upon_30_end_offset_20: "30 days net end of month, the 20th",
                    none: "None",
                },
                price_formats: {
                    vat_incl: "VAT included",
                    vat_excl: "VAT excluded",
                },
                payment_info: "Payment information",
                late_penalty_rate_input_label: "Late penalty rate",
                rebate_rate_input_label: "Rebate rate",
                payment_terms_input_label: "Payment terms",
                payment_method_input_label: "Payment methods",
                bank_transfer: "Bank transfer",
                iban_input_label: "IBAN",
                bic_input_label: "BIC",
                payment_email_input_label: "Email",
                bank_input_label: "Bank",
                default_values: "Default values",
                sales_type_input_label: "Sales type",
                price_format_input_label: "Price Format",
                vat_input_label: "VAT",
                no_results_found: "No results found.",
                condition_notes_input_label: "Conditions, notes, payment plan...",
                term_and_conditions: "Terms and conditions",
                signature: "Signature",
                primary_color: "Primary color",
                select_color_input_label: "Select color",
                loading_pdf: "Loading PDF",
                of: "of",
            },
            email: {
                title: "Email",
                common_heading: "Common",
                include_footer: "Include footer",
                include_title: "Include title",
                include_logo: "Include logo",
                insert_tag: "Insert a tag:",
                invoices: "Invoices",
                credit_notes: "Credit notes",
                quotes: "Quotes",
                delivery_notes: "Delivery notes",
                title_input_label: "Title",
                alignment: {
                    top_left_input_label: "Top left",
                    top_right_input_label: "Top right",
                    bottom_left_input_label: "Bottom left",
                    bottom_right_input_label: "Bottom right",
                },
                languages: {
                    french: "French",
                    english: "English",
                    german: "German",
                    italian: "Italian",
                    spanish: "Spanish"
                },
                tags: {
                    your_company_name: "Your company name",
                    your_company_email: "Your company email",
                    invoice_number: "Invoice number",
                    invoice_amount: "Invoice amount",
                    order_number: "Order number",
                    due_date: "Due date",
                }
            },
            error: {
                file_upload_fail: "File upload fail",
                not_found: "No activity found",
                name: "Name is required",
                must_allowed: "Min {{amount}} allowed",
                late_penalty_rate: "Penalty rate is required",
                rebate_rate: "Rebate rate is required",
                payment_term: "Payment term is required",
                template_name: "Template is required",
                template_color: "Template color is required",
                sales_type: "Sales type is required",
                tax_rate: "Tax rate is required",
                title: "Title is required",
                body: "Body is required",
                email: "Invalid email"
            },
        }
    },
    notification: {
        nav_title: "Notifications",
        heading: "Notifications",
        description: "Description",
        selected: "Selected",
        deleted: "Deleted",
        see_more: "See more",
        cancel_btn: "Cancel",
        delete_btn: "Delete",
        cancel_view_btn: "Cancel",
        warning: "Warning",
        ok: "OK",
        notification: "Notification",
        notifications: "Notifications",
        delete_notifications: "Delete notifications?",
        confirm_delete: "This operation cannot be reversed!",
        store: {
            disabled: {
                title: "Synchronization Suspended",
                message: "The synchronization of your store {{store}} has been suspended.",
                details: "The synchronization has been automatically suspended due to the deactivation of your account or store. Please go to the 'Integrations' menu to reactivate the synchronization."
            },
            error: {
                title: "Synchronization Error",
                message: "An error occurred during the synchronization of your store {{store}}.",
                details: "Your store cannot be synchronized. Please check the connection settings and contact us if the error persists."
            }
        },
        order: {
            error: {
                title: "Order Synchronization Error",
                message: "An error occurred during the synchronization of order {{order}} from your store {{store}}.",
                details: "The invoice for order {{order}} could not be issued. Please contact us for more information.",
                refund: {
                    title: "Refund Synchronization Error",
                    message: "An error occurred during the synchronization of order {{order}} refund from your store {{store}}.",
                    details: "The credit for order {{order}} could not be issued. Please contact us for more information."
                }
            },
            unsupported: {
                title: "Order Synchronization Not Possible",
                message: "Order {{order}} from your store {{store}} cannot be synchronized.",
                details: "The invoice for order {{order}} could not be finalized. A draft has been created for you to manually validate the invoice.",
                refund: {
                    title: "Refund Synchronization Not Possible",
                    message: "Order {{order}} from your store {{store}} cannot be synchronized.",
                    details: "The credit for order {{order}} could not be finalized. A draft has been created for you to manually validate the credit."
                },
                currency: {
                    title: "Order Synchronization Not Possible",
                    message: "The currency of order {{order}} from your store {{store}} is not supported.",
                    details: "The invoice for order {{order}} could not be issued because the currency of this order is not supported."
                },
                shopify: {
                    outstanding: {
                        positive: {
                            title: "Synchronization Not Possible",
                            message: "Order {{order}} from your store {{store}} has a pending payment.",
                            details: "The invoice for order {{order}} could not be finalized because a payment is pending. Please check the order in your Shopify store. A draft has been created for you to manually validate the invoice."
                        },
                        negative: {
                            title: "Synchronization Not Possible",
                            message: "Order {{order}} from your store {{store}} has a pending refund.",
                            details: "The invoice for order {{order}} could not be finalized because a refund is pending. Please check the order in your Shopify store. A draft has been created for you to manually validate the invoice."
                        }
                    }
                }
            }
        },
        bank: {
            error: {
                title: "Update Required",
                message: "The synchronization with your bank {{bank}} needs to be updated.",
                details: "An action is required to restore the connection with your bank. Please go to the 'Banks' menu and follow the instructions."
            }
        },
        unread_popup: {
            heading: "Warning",
            description: "You have pending notifications",
            see_more_btn: "See more",
        },
    },
    page_404: {
        not_found: "404 Page Not Found",
        sorry_not_found: "Sorry, page not found!",
        sorry_could_not_find: "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.",
        go_home: "Go to Home",
    },
    language: {
        fr: "French",
        en: "English",
        cimode: "CI Mode"
    },
    all_language_labels: {
        customer_language: "Customer language",
        french: "French",
        english: "English",
        german: "German",
        italian: "Italian",
        spanish: "Spanish"
    },
    templates: {
        simple: "Simple",
        london: "London",
        paris: "Paris",
        berlin: "Berlin"
    },
    common: {
        upload_photo: "Upload photo",
        something_wrong: "An error occurred",
        drop_or_select_file: "Drop or Select file",
        drop_files_here: "Drop files here or click",
        browse: "browse",
        through_your_machine: "thorough your machine",
        write_something_awesome: "Write something awesome...",
        loading: "Loading...",
        update_photo: "Update photo",
        remove_all: "Remove all",
        forbidden: "Forbidden",
        go_home: "Go to home",
        made_by: "Made by Bizyness",
        permission_denied: "You don’t have permission",
    },
    public: {
        invoice: {
            invalid_token: "Invalid token",
            days: "Days",
            delay_before_due_date: "Deadline",
            status_of_payments: "Payments",
            payment_method: "Pay the invoice",
            attachments: "Attachments",
            connect_bizyness: "Contact with Bizyness",
            download_pdf: "Download invoice (PDF)",
            total_amount: "Total amount:",
            sent_on: "Sent on:",
            invoice: "INVOICE",
            payment_methods: {
                credit_card: "Credit card",
                paypal: "PayPal",
            },
            success_popup: {
                heading: "Payment confirmed",
                description: "Your payment is processing, it will be confirmed very soon.",
                okay_btn: "Okay",
            },
            status_tag: {
                paid: "Paid",
                not_paid: "Not paid",
                cancelled: "Cancelled",
                overdue: "Overdue",
                null: ""
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            warning_overdue_days: "Warning! Payment is now overdue by {{days}} days!",
            warning_overdue_day: "Warning! Payment is now overdue by {{day}} day!",
            overdue: "Overdue"
        },
        quote: {
            invalid_token: 'Invalid token',
            days: "Days",
            delay_before_due_date: "Deadline",
            status_of_payments: "Payments",
            payment_method: "Payment method",
            attachments: "Attachments",
            connect_bizyness: "Contact with Bizyness",
            download_pdf: "Download quote (PDF)",
            total_amount: "Total amount:",
            sent_on: "Sent on:",
            invoice: "INVOICE",
            accept_btn: "Accept",
            refuse_btn: "Refuse",
            accepted: "Accepted",
            refused: "Refused",
            notes_heading: "Notes",
            write_comment: "Write a comment",
            send_btn: "Send",
            sent_alert: "Note sent",
            status_tag: {
                pending: "Pending",
                accepted: "Accepted",
                declined: "Declined",
                null: ""
            },
            pdf: {
                loading_pdf: "Loading PDF",
                of: "Of",
            },
            accept_popup: {
                heading: "Warning",
                description: "Are you sure you want to accept?",
                cancel_btn: "No",
                confirm_btn: "Yes",
            },
            refuse_popup: {
                heading: "Warning",
                description: "Are you sure you want to refuse?",
                cancel_btn: "No",
                confirm_btn: "Yes",
            }
        }
    },
    upgrade: {
        upgrade_now_btn: "Get feature"
    },
    errors: {
        activity: {
            linked_items: "Unable to delete this activity because it is linked to invoices, quotes, or integrations",
        },
        subscription: {
            payment_failed: "Verify your payment method before activating your account",
        },
        integration: {
            shopify: {
                existing_store: "This store is already linked to another Bizyness account",
                connection: "Unable to connect to your Shopify store, please try again",
                no_store: "Please connect your Shopify store before changing plan"
            }
        }
    },
    plans: {
        current: "Current",
        best_fit: "Recommended",
        compare_plans: "Compare all plans",
        upgrade_btn: "Upgrade",
        upgrade_note: "Upgrade your subscription to unlock this feature",
        plus: "Plus:",
        free: "Free",
        per_monthly: "/month",
        per_yearly: "/year",
        plan_updated: "Your subscription has been updated",
        upgrade_to: "Upgrade to ",
        excluding_vat: "Excl. VAT",
        upgrade: "Change your subscription",
        your_new_subscription: "Your new subscription:",
        your_current_subscription: "Your current subscription:",
        frequency: {
            monthly: "Monthly",
            month: "month",
            yearly: "Yearly",
            annum: "annual"
        },
        essential: {
            title: 'Essential',
        },
        legacy1: {
            title: 'Legacy plan',
        },
        pro: {
            title: 'Pro',
        },
        business: {
            title: 'Business',
        },
        elite: {
            title: 'Elite',
        },
        features: {
            quotes: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Quotes",
                btn_subtitle: "Quote management and more"
            },
            payments: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Revenue book",
                btn_subtitle: "Revenue book and more"
            },
            down_payment_invoices: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Down payment invoices",
                btn_subtitle: "Down payment invoices and more"
            },
            customer_files: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Customer file",
                btn_subtitle: "Customer file and more"
            },
            item_catalog: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Item catalog",
                btn_subtitle: "Item catalog and more"
            },
            customize_documents: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Document customization",
                btn_subtitle: "Document customization and more"
            },
            receipt_book: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Receipt book",
                btn_subtitle: "Receipt book and more"
            },
            expense_register: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Purchase register",
                btn_subtitle: "Purchase register and more"
            },
            supplier_file: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Supplier file",
                btn_subtitle: "Supplier file and more"
            },
            csv_export: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "CSV Export",
                btn_subtitle: "CSV Export and more"
            },
            urssaf_report_micro_enterprises: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "URSSAF Report",
                btn_subtitle: "URSSAF Report and more"
            },
            activity_report: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Activity report",
                btn_subtitle: "Activity report and more"
            },
            invoices_credit_notes: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Invoices and credit notes",
                btn_subtitle: "Invoice management and more",
            },
            vat_invoicing: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "VAT management",
                btn_subtitle: "VAT management and more"
            },
            auto_mandatory: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Automatic legal notices",
                btn_subtitle: "Automatic legal notices and more"
            },
            import_invoices: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Import invoices",
                btn_subtitle: "Import invoices and more"
            },
            import_articles: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Import items",
                btn_subtitle: "Import items and more"
            },
            import_customer: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Import customers",
                btn_subtitle: "Import customers and more"
            },
            payment_certificates: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Payment certificates",
                btn_subtitle: "Payment certificates and more"
            },
            recurring_invoices: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Recurring invoices",
                btn_subtitle: "Recurring invoices and more"
            },
            send_quotes_invoices: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Send quotes and invoices",
                btn_subtitle: "Send quotes and invoices and more"
            },
            acknowledgement_receipts: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Acknowledgment receipts",
                btn_subtitle: "Acknowledgment receipts and more"
            },
            delivery_notes: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Delivery notes",
                btn_subtitle: "Delivery notes and more"
            },
            attachments: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Attachments",
                btn_subtitle: "Attachments and more"
            },
            multi_language: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Multilingual documents",
                btn_subtitle: "Multilingual documents and more"
            },
            multi_currency: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Multi-currency documents",
                btn_subtitle: "Multi-currency documents and more"
            },
            online_bill_payment: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Online payments",
                btn_subtitle: "Online payments and more"
            },
            import_receipts: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Import receipts",
                btn_subtitle: "Import receipts and more"
            },
            import_expenses: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Import expenses",
                btn_subtitle: "Import expenses and more"
            },
            receipts_storage: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Receipt storage",
                btn_subtitle: "Receipt storage and more"
            },
            recurring_expenses: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Recurring expenses",
                btn_subtitle: "Recurring expenses and more"
            },
            expenditure_categories: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Expense categories",
                btn_subtitle: "Expense categories and more"
            },
            bank_categorize_rules: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Bank categorization rules",
                btn_subtitle: "Bank rules and more"
            },
            multi_user: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Multi-user management",
                btn_subtitle: "Team management and more"
            },
            vat_report: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "VAT report",
                btn_subtitle: "VAT report and more"
            },
            margin_vat_report: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Margin VAT report",
                btn_subtitle: "Margin VAT report and more"
            },
            ioss_oss_report: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "IOSS/OSS report",
                btn_subtitle: "IOSS/OSS report and more"
            },
            export_fec_accounting: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "FEC accounting export",
                btn_subtitle: "FEC accounting export and more"
            },
            api_access: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "API access",
                btn_subtitle: "API access and more"
            },
            zapier_connection: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Zapier connection",
                btn_subtitle: "Zapier connection and more"
            },
            sync_products: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Product synchronization",
                btn_subtitle: "Product synchronization and more"
            },
            auto_vat: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Automatic VAT",
                btn_subtitle: "Automatic VAT and more"
            },
            sync_pay_commission: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Fee synchronization",
                btn_subtitle: "Fee synchronization and more"
            },
            phone_support: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Phone support",
                btn_subtitle: "Phone support and more"
            },
            priority_support: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Priority support",
                btn_subtitle: "Priority support and more"
            },
            online_platform_connection: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "E-commerce connections",
                btn_subtitle: "E-commerce connections and more"
            },
            banks_sync: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Bank synchronization",
                btn_subtitle: "Bank synchronization and more"
            },
            multi_activity_management: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Multi-activity management",
                btn_subtitle: "Multi-activity management and more"
            },
            limited_support: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Limited support",
                btn_subtitle: "Limited support and more"
            },
            basic_support: {
                btn_title: "Upgrade your subscription to unlock this feature",
                name: "Basic support",
                btn_subtitle: "Basic support and more"
            },
        }
    },
    frill: {
        idea: {
            view_idea_btn: "Idea Box",
            tag_line: "Let’s improve Bizyness together"
        }
    }
};

export default en;
