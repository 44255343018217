import { Box, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, Link, Radio, RadioGroup, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import Iconify from 'src/components/iconify';
import useLocales from 'src/hooks/useLocales';
import { formatCurrency } from 'src/utils/formatNumber';
import PackageSelection from './PackageSelection';
import { plans as AccountPlansArray } from './plans';
import { moveStringToTop } from 'src/utils/language';
import { PaymentFrequency, PlanDetails } from 'src/@types/plans/plans';
import { dispatch, useSelector } from 'src/redux/store';
import { togglePlans } from 'src/redux/slices/plans/plans';
import { changeAccountPlan } from 'src/redux/slices/auth/auth';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { getMinimumPlanForFeature } from 'src/utils/features';
import { get } from 'lodash';

// ---------------------------------------------------------------

function AccountPlanDialog() {
    const theme = useTheme();

    const { translate, currentLang: { value: language } } = useLocales();

    const [value, setValue] = React.useState<PaymentFrequency>('yearly');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { setValue((event.target as HTMLInputElement).value as PaymentFrequency); };

    const { open, currentPlan, requiredFeature, interval, provider } = useSelector(state => state.plans);

    const PlansArray: PlanDetails[] = get(AccountPlansArray, provider, []);

    const { isLoading } = useSelector(state => state.auth);

    const [selectedPackage, setSelectedPackage] = useState<string>('');

    const [allPackages, setAllPackages] = useState<PlanDetails[]>([]);

    useEffect(() => {
        if (!open) return;
        const plans = !currentPlan.includes('legacy') ? PlansArray.filter(pkg => !pkg.title.includes('legacy')) : PlansArray;

        if (requiredFeature) {
            const filteredPackages = [];
            // find current plan
            const currentPkg = plans.find(pkg => pkg.title === currentPlan)
            if (currentPkg) filteredPackages.push({ ...currentPkg, label: 'plans.current' });

            // find the minimum plan that offers required feature
            const supportedPlan = getMinimumPlanForFeature(requiredFeature);
            const bestFitPlan = supportedPlan ? plans.find(pkg => pkg.title === supportedPlan) : plans.find(pkg => pkg.title === 'business');

            if (bestFitPlan) {
                setSelectedPackage(bestFitPlan.title ?? '');
                // Create a new object to prevent mutation
                const updatedPlan = {
                    ...bestFitPlan,
                    features: bestFitPlan.features.includes(requiredFeature)
                        ? moveStringToTop([...bestFitPlan.features], requiredFeature)
                        : moveStringToTop([...bestFitPlan.features, requiredFeature], requiredFeature)
                };

                filteredPackages.push({
                    ...updatedPlan,
                    label: 'plans.best_fit'
                });

            } else {
                // set default selected pkg to required plan otherwise current plan
                setSelectedPackage(currentPkg?.title ?? '');
            }

            setAllPackages(filteredPackages)
        } else {
            setAllPackages(plans.map(pkg => {
                if (pkg.title === currentPlan) {
                    return { ...pkg, label: 'plans.current' }
                }
                if (pkg.features.includes(requiredFeature)) return { ...pkg, features: moveStringToTop(pkg.features, requiredFeature), label: 'plans.best_fit' }
                return { ...pkg, label: '', }
            }))
            if (!selectedPackage) setSelectedPackage(currentPlan)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan, requiredFeature, open])

    const changePlanFun = (plan: string) => setSelectedPackage(plan)

    const plan = PlansArray.find(p => p.title === selectedPackage);

    const onClose = () => {
        dispatch(togglePlans({ requiredFeature }));
        setSelectedPackage('');
        setAllPackages([]); // Reset packages to prevent stale data
    }

    const upgradeFunction = async () => {
        try {
            let plan = selectedPackage;
            if (selectedPackage !== 'essential') plan = `${selectedPackage}_${value}`;
            const response = await dispatch(changeAccountPlan(plan));
            if (response.success) {
                if (response.url) window.location.href = response.url;
                else enqueueSnackbar({ message: translate('plans.plan_updated') });
                onClose()
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar({
                message: translate('commonm.something_wrong'),
                variant: 'error'
            });
        }
    }

    const mainPrice = value === 'yearly' ? plan?.yearly.month : plan?.monthly.price;

    return (
        <Dialog fullWidth open={open} maxWidth={requiredFeature ? 'md' : 'xl'} onClose={onClose}>
            <DialogContent sx={{ m: 0, p: 0 }}>
                <Grid container sx={{ minHeight: 400 }}>
                    <Grid item xs={12} sm={12} md={requiredFeature ? 8 : 9} lg={requiredFeature ? 8 : 9} xl={requiredFeature ? 8 : 9} sx={{ p: 2 }}>
                        <Box >
                            <Box display={'flex'}>
                                <Iconify icon={"eva:arrow-circle-up-outline"} width={32} height={32} color={theme.palette.primary.main} sx={{ mr: 2 }} />
                                <Typography variant='h4'>{translate('plans.upgrade')}</Typography>
                            </Box>
                            <PackageSelection frequency={value} changePlanFun={changePlanFun} allPackages={allPackages} selectedPackage={selectedPackage} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={requiredFeature ? 4 : 3} lg={requiredFeature ? 4 : 3} xl={requiredFeature ? 4 : 3} sx={{ backgroundColor: theme.palette.grey[100], p: 2, pl: 3, pr: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton sx={{ position: 'absolute', right: 5, top: 5, }} onClick={onClose}>
                                <Iconify icon={'eva:close-fill'} width={24} height={24} />
                            </IconButton>
                            <Typography variant='subtitle1' sx={{ mt: 2 }}>{selectedPackage === currentPlan ? translate('plans.your_current_subscription') : translate('plans.your_new_subscription')}</Typography>
                            <Typography variant="h5" sx={{ mt: 1 }}>
                                {translate(`plans.${selectedPackage}.title`)}
                                {selectedPackage !== 'essential' && ` (${translate(`plans.frequency.${value}`)})`}
                            </Typography>
                            {selectedPackage !== 'essential' ? <>
                                <Box sx={{ display: 'flex', mt: 1 }}>
                                    <Typography variant='h2' color={theme.palette.primary.main}>{formatCurrency(mainPrice, language, (mainPrice ?? 0) % 1 === 0 ? 0 : 2, plan?.currency ?? 'EUR')}</Typography>
                                    <Stack direction={'column'} sx={{ ml: 1, height: 'min-content', alignSelf: 'center' }}>
                                        <Typography variant='body1' color={theme.palette.grey[600]} lineHeight={1}>
                                            {translate('plans.excluding_vat')}
                                        </Typography>
                                        <Typography variant='h6' lineHeight={1}>/{translate('plans.frequency.month')}</Typography>
                                    </Stack>
                                </Box>
                                {value === 'yearly' && <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ textDecoration: 'line-through', fontWeight: 400, color: theme.palette.grey[600], mr: 0.5 }} >{formatCurrency(plan?.yearly.off, language, (plan?.yearly.off ?? 0) % 1 === 0 ? 0 : 2, plan?.currency ?? 'EUR')}</Typography>
                                    <Typography variant='subtitle1' mr={0.5}>{formatCurrency(plan?.yearly.year, language, (plan?.yearly.year ?? 0) % 1 === 0 ? 0 : 2, plan?.currency ?? 'EUR')}</Typography>
                                    <Typography variant='body1' color={theme.palette.grey[600]}>/{translate('plans.frequency.annum')}</Typography>
                                </Box>}
                                {!selectedPackage.includes('legacy') && <FormControl component="fieldset" sx={{ mt: 3, width: '100%' }}>
                                    <RadioGroup aria-label="subscription" name="subscription" value={value} onChange={handleChange} row>
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControlLabel value="yearly" control={<Radio />} label={translate('plans.frequency.yearly')} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1, p: 1, m: 0, boxShadow: theme.shadows[2], width: '100%' }} />
                                            </Grid>
                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControlLabel value="monthly" control={<Radio />} label={translate('plans.frequency.monthly')} sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1, p: 1, m: 0, boxShadow: theme.shadows[2], width: '100%' }} />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>}
                            </> :
                                <Typography variant='h2' color={theme.palette.primary.main}>{translate('plans.free')}</Typography>
                            }
                            {!selectedPackage.includes('legacy') && <LoadingButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mt: 3 }}
                                size="large"
                                disabled={currentPlan === selectedPackage && interval === value}
                                onClick={upgradeFunction}
                                loading={isLoading}
                            >
                                {translate('plans.upgrade_to')} {translate(`plans.${selectedPackage}.title`)}
                                {selectedPackage !== 'essential' && ` (${translate(`plans.frequency.${value}`)})`}
                            </LoadingButton>}

                        </Box>
                        <Box mt={2} display="flex" justifyContent="center">
                            <Link target='_blank' href='https://www.bizyness.fr/tarifs' sx={{ ':hover': { textDecoration: 'none' } }}>{translate('plans.compare_plans')}</Link>
                        </Box>
                    </Grid>
                </Grid>

            </DialogContent>

        </Dialog >
    )
}

export default AccountPlanDialog