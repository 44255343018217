import { Alert, Button, Stack, Typography, useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { isTrailPeriod } from "../dashboard/header";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

export default function TopHeaderAlert() {
    const theme = useTheme();

    const navigate = useNavigate();

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSelector(state => state.auth);

    const isTrailPeriodRunning = isTrailPeriod(user, translate);

    const viewPlansFunc = async () => {
        try {
            navigate(PATH_DASHBOARD.general.profile + '?tab=billing')
        } catch (error) {
            console.log(error);
            enqueueSnackbar(translate('common.something_wrong'), { variant: 'error' })
        }
    }

    return (<>
        {isTrailPeriodRunning && <Alert
            variant='outlined'
            severity='warning'
            icon={false}
            sx={{
                p: 0,
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.darker,
                borderRadius: 0,
                display: 'block',
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
                '& .MuiAlert-message': {
                    padding: '2px 0'
                }
            }}>
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{
                    width: '100%',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column', // Change direction to column on small screens
                        alignItems: 'center', // Align items to the start on small screens
                    },
                }}>
                <Typography variant="caption">
                    {translate('trial.ends_in')}{' '}
                    {/* <Typography component="span" variant="subtitle1"> */}
                    <b>{isTrailPeriodRunning}</b>
                    {/* </Typography> */}
                    {' '}
                    {translate('trial.upgrade')}
                </Typography>

                <Button
                    size="small"
                    variant='contained'
                    onClick={viewPlansFunc}
                    sx={{
                        typography: 'caption',
                        height: 24,
                        borderRadius: 0.5,
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.grey[800],
                        ":hover": {
                            backgroundColor: theme.palette.grey[300]
                        }
                    }}
                >
                    {translate('trial.view_plan_btn')}
                </Button>
            </Stack>
        </Alert>}</>)
}