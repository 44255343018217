import Box from '@mui/material/Box';

import useBoolean from 'src/hooks/useBoolean';
import useResponsive from 'src/hooks/useResponsive';

import { useSettingsContext } from 'src/components/settings';
import Main from './main';
import Header, { isAccountCancelled, isTrailPeriod } from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { Typography, useTheme } from '@mui/material';
import { useSelector } from 'src/redux/store';
import useLocales from 'src/hooks/useLocales';
import TopHeaderAlert from '../common/top-header-alert';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Readonly<Props>) {

  const { user } = useSelector(state => state.auth);

  const { translate } = useLocales();

  const isAccountSuspended = isAccountCancelled(user);

  const isTrailPeriodRunning = isTrailPeriod(user, translate);

  const settings = useSettingsContext();

  const theme = useTheme();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal isAccountSuspended={isAccountSuspended} isTrailPeriodRunning={isTrailPeriodRunning} />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const copyrightSection =
    <div style={{
      textAlign: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      bottom: 0,
      position: 'fixed',
      width: '100%',
      left: 0,
      backgroundColor: theme.palette.background.default
    }}>
      <Typography variant='body2' color={theme.palette.grey[600]}>Bizyness © {new Date().getFullYear()}</Typography>
    </div>

  if (isHorizontal) {
    return (
      <>
        <TopHeaderAlert />
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
        {copyrightSection}

      </>
    );
  }

  if (isMini) {
    return (
      <>
        <TopHeaderAlert />
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
          {copyrightSection}

        </Box>
      </>
    );
  }

  return (
    <>
      <TopHeaderAlert />

      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
        {copyrightSection}
      </Box>
    </>
  );
}
