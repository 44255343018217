import { Badge, IconButton, SvgIcon } from '@mui/material';
import { m } from 'framer-motion';
import React from 'react';
import { FrillWidget } from 'src/@types/frill/frill';
import { varTap } from 'src/components/animate';
import useLocales from 'src/hooks/useLocales';
import { useSelector } from 'src/redux/store';


const FrillNotificationBadge: React.FC = React.memo(() => {

    const { user } = useSelector(state => state.auth);

    const { currentLang: { value: language } } = useLocales();

    // Use component state to show the number of unread announcements
    const [count, setCount] = React.useState(0);

    const [loaded, setLoaded] = React.useState(false);

    // We need to keep a reference to the widget instance so we interact with it
    const widgetRef = React.useRef<FrillWidget>();

    const openAnnouncements = () => {
        // First check that there is a widget
        if (widgetRef.current) {
            widgetRef.current.open();
            // Mark all announcements as read so the notification badge won't appear again
            // until there are new announcements
            (widgetRef.current as any).markAsRead();
        }
    }

    // This effect will run once when the component has mounted
    React.useEffect(() => {
        console.log('** Initializing Frill Announcements');

        (window?.Frill as any)('widget', {
            key: "6a29ae76-36c0-4a9f-b24f-36fc526d4ff4", // Replace with your widget key
            ssoToken: user?.transient_attributes?.frill_token ?? '',
            language: language,
            callbacks: {
                onReady(widget: any) {
                    console.log('Ready Frill Announcements!')
                    widgetRef.current = widget;
                    setLoaded(true);
                },
                onBadgeCount({ announcements, count }: { announcements: any[]; count: number }) {
                    setCount(count);
                },
            },

        });

        return () => {
            console.log('destroying frill announcement')
            if (widgetRef.current?.destroy) {
                widgetRef.current.destroy();
                widgetRef.current = undefined;
                setLoaded(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.transient_attributes?.frill_token, language]);

    // This is a link to view all announcements on the frill platform
    return (
        <>
            {loaded ?
                <IconButton
                    key={'notifications'}
                    component={m.button}
                    whileTap={varTap(0.96)}
                    onClick={openAnnouncements}
                >
                    <Badge badgeContent={count} color="error">
                        <SvgIcon>
                            {/* https://icon-sets.iconify.design/solar/bell-bing-bold-duotone/ */}
                            <path
                                fill="currentColor"
                                d="M18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 0 1-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723a4.393 4.393 0 0 0 .693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7"
                                opacity="0.5"
                            />
                            <path
                                fill="currentColor"
                                d="M12.75 6a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0zM7.243 18.545a5.002 5.002 0 0 0 9.513 0c-3.145.59-6.367.59-9.513 0"
                            />
                        </SvgIcon>
                    </Badge>
                </IconButton> : null}
        </>
    )
});

export default FrillNotificationBadge;